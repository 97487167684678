import { useState } from 'react'

export interface IUsePremiumModalHook {
    open: boolean
    setModalOpen: (state: boolean) => void
}

export const usePremiumModal = () => {
    const [open, setOpen] = useState<boolean>(false)

    const setModalOpen = (state: boolean) => {
        setOpen(state)
    }

    return {
        open,
        setModalOpen,
    } as IUsePremiumModalHook
}