export const isSafari = (): boolean => {
    return navigator.userAgent.toLowerCase().indexOf('safari') > -1
}

export const getSafariVersion = (): number => {
    const splitAgent = [...navigator?.userAgent?.split('Version/')]
    if(splitAgent.length > 1) {
        const array = splitAgent[1]
        const [first, second] = array
        const version = first + second
        return Number(version)
    } else {
        return -1
    }
}
