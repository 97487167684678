import { PrivateRoute } from './features/components/routing/PrivateRoute'
import { lazy } from 'react'

const Dashboard = lazy(() => import('./features/pages/dashboard/Dashboard'))
const UserSubscriptionDetails = lazy(() => import('./features/pages/premium/UserSubscriptionDetails'))
const UserProfile = lazy(() => import('./features/pages/profile/UserProfile'))
const Utilities = lazy(() => import('./features/pages/tools/Utilities'))
const UserPlanning = lazy(() => import('./features/pages/planning/UserPlanning'))

interface IPrivateRouteProps {
    isUserAuthenticationChecked: boolean
    isUserAuthenticated: boolean
}

export const PrivateRoutes = (props: IPrivateRouteProps) => {
    const { isUserAuthenticationChecked, isUserAuthenticated } = props

    return <>
    <PrivateRoute 
        authed={!isUserAuthenticationChecked || isUserAuthenticated} 
        path="/dashboard" 
        component={Dashboard} />
    <PrivateRoute 
        authed={!isUserAuthenticationChecked || isUserAuthenticated} 
        path="/profile"
        component={UserProfile}
    />
    <PrivateRoute 
        authed={!isUserAuthenticationChecked || isUserAuthenticated} 
        path="/planning"
        component={UserPlanning}
    />
    <PrivateRoute
        authed={!isUserAuthenticationChecked || isUserAuthenticated}
        path="/utilities"
        component={Utilities}
    />
    <PrivateRoute
        authed={!isUserAuthenticated || isUserAuthenticationChecked}
        path="/user/subscription"
        component={UserSubscriptionDetails}
    />
</>
}