import { Avatar, Collapse, List, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import { IUserPet } from '../../../app/types/types'
import { Link as RouterLink } from 'react-router-dom'
import { useState } from 'react'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { PetAvatarPlaceholder } from '../image/PetAvatarPlaceholder'

const useStyles = makeStyles((theme) => ({
    imageDisplay: {
		width: theme.spacing(5),
		height: theme.spacing(5),
		background: '#409b63',
		borderRadius: 4,
        display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
        marginRight: '2em'
    },
    placeholderIcon: {
        fontSize: 30,
        color: '#f3f4e4',
        display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
    },
    petOptionsList: {
        marginLeft: '5em'
    },
    petNavListItem: {
        marginTop: '1em'
    }
}))

interface IPetNavigationProps extends IUserPet {}

export const PetNavigation = (props: IPetNavigationProps) => {
    const classes = useStyles()
    const [petMenusOpen, setPetMenusOpen] = useState<boolean>(true)

    const handleMenuExpansion = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setPetMenusOpen((previous) => !previous)
    }

    return <>
        <List className={classes.petNavListItem}>
            <ListItem>
                { props.imageUrl ? 
                    <Avatar 
                        className={classes.imageDisplay}
                        alt={props.name} 
                        src={props.imageUrl}
                        variant="rounded"/> 
                    : <PetAvatarPlaceholder 
                        className={classes.imageDisplay}
                        iconClassName={classes.placeholderIcon}
                    />
                }
                <ListItemText primary={props.name}/>
                {petMenusOpen ? <ExpandLess onClick={handleMenuExpansion}/> : <ExpandMore onClick={handleMenuExpansion}/>}
            </ListItem>
            <Collapse 
                in={petMenusOpen} 
                timeout='auto' 
                unmountOnExit>
                    <List
                        className={classes.petOptionsList}
                    >
                        <ListItem>
                            <RouterLink to={`/dashboard/pet/${props.id}`}>Details</RouterLink>
                        </ListItem>
                        <ListItem>
                            <RouterLink to={`/dashboard/pet/${props.id}/notes`}>Notes</RouterLink>
                        </ListItem>
                        <ListItem>
                            <RouterLink to={`/dashboard/pet/${props.id}/skills`}>Skills</RouterLink>
                        </ListItem>
                        <ListItem>
                            <RouterLink to={`/dashboard/pet/${props.id}/metrics`}>Metrics</RouterLink>
                        </ListItem>
                        { props.permissionLevel === 0 ?
                            <ListItem>
                                <RouterLink to={`/dashboard/pet/${props.id}/settings`}>Settings</RouterLink>
                            </ListItem> : null
                        }
                    </List>
            </Collapse>
        </List>
    </>
}