import { Box, Button, Typography, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import ProgressiveImage from '../image/ProgressiveImage'
import ErrorImage from '../../../assets/images/error_occurred.jpg'

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '2em auto',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '500px',
            margin: '2em auto',
        },
        [theme.breakpoints.up('lg')]: {
            width: '1000px',
        }
    },
    header: {
        margin: '1em',
        fontSize: '2em',
        color: '#1B422A',
        fontWeight: 'bold'
    },
    errorImage: {
        height: '400px',
        borderRadius: '10px',
        border: '5px solid #e3f4ef',
        zIndex: -1,
        [theme.breakpoints.up('sm')]: {
            height: '600px'
        }
    }
}))

interface IErrorFallbackProps {
    error: Error
}

export const ErrorFallback = (props: IErrorFallbackProps) => {
    const classes = useStyles()
    const showErrorMessage = process.env.NODE_ENV === 'development'
    
    return (
        <Box role='alert'
            className={classes.container}>
            <Typography 
                className={classes.header}
                variant={'h1'}>Something went wrong!</Typography>
            <ProgressiveImage 
                className={classes.errorImage}
                alt={'Dog destroying a piñata'}
                previewSrc={ErrorImage}
                imgSrc='https://res.cloudinary.com/dsv5abc3a/image/upload/v1684035383/Missile_Piniata_02.jpg'/>
            <Typography variant={'body1'}>But no worries! We have been notified of the issue and are hard at work fixing it.</Typography>
            { showErrorMessage ?
                <pre style={{color: 'red'}}>{props.error.message}</pre>
                : null    
            }
            <Button 
                variant={'contained'} 
                color={'primary'} 
                component={Link} 
                to='/home'>Take me back home!</Button>
        </Box>
    )
}