import { Box, Icon, Typography, makeStyles } from '@material-ui/core'
import { TemporaryDrawer } from '../../components/TemporaryDrawer'
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    hiddenDesktop: {
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    hiddenNonDesktop: {
        width: '216px',
        paddingLeft: '30px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    linkDesktop: {
        marginLeft: '2em'
    }
}))

export const UserLoggedInLinks = () => {
    const classes = useStyles()

    return (
        <>
        <Box className={classes.hiddenNonDesktop}>
            <Icon>
                <DashboardRoundedIcon />
            </Icon>
            <Typography 
                className={classes.linkDesktop}
                component={Link} 
                to="/dashboard" 
                color="inherit">Dashboard</Typography>
        </Box>
        <Box className={classes.hiddenDesktop}>
            <TemporaryDrawer initializedOpen={false}/>
        </Box>
        </>
    )
}