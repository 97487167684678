export const PETIDTYPES = {
  LIKES: 1,
  DISLIKES: 2,
  TRICKS: 3,
  SKILLS: 4,
  CUEDSKILLSINPROGRESS: 6,
  UNCUEDSKILLSINPROGRESS: 7,
  SKILLSCOMPLETE: 8,
  GOALS: 9,
  TITLES: 10
}

export enum petPermissionsLevels {
    OWNER = 0,
    FULL_PERMISSIONS = 1,
    READ_ONLY = 2
  }

export const editablePermissionsLevels = [petPermissionsLevels.OWNER, petPermissionsLevels.FULL_PERMISSIONS]

export const ownerPermissionsLevels = [petPermissionsLevels.OWNER]

export const defaultApiResponse = {
  status: 500,
  data: {
    error: 'An error occurred retrieving data'
  }
}