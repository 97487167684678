import { Backdrop, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
)

interface IBackdropLoaderProps {
  loading: boolean
}

export const BackdropLoader = (props: IBackdropLoaderProps) => {
    const { loading } = props
    const classes = useStyles()

    return (
        <Backdrop 
            className={classes.backdrop} 
            open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )

}