import React, { useEffect, useState } from 'react'

interface IProgressiveImageProps {
    imgSrc: string
    previewSrc: string
    className: string
    alt: string
}

export default function ProgressiveImage (props: IProgressiveImageProps) {

    const [ usedSrc, setUsedSrc ] = useState(props.previewSrc)
    const [ usedEffectStyle, setUsedEffectStyle ] = useState<any>({ filter: 'blur(5px)', clipPath: 'inset(0)' })

    useEffect(() => {
        const img = new Image()
        img.src = props.imgSrc
        img.onload = () => {
            setUsedSrc(img.src)
            setUsedEffectStyle({})
        }
    }, [props.imgSrc])

    return <img 
        alt={props.alt}
        className={props.className}
        src={usedSrc} 
        style={{ transition: 'filter 0.1s ease-out', ...usedEffectStyle }} />

}