import React, { Suspense } from 'react'
import { IUseProfileHook } from './contexts/UserAuthenticationContext'
import { getSafariVersion, isSafari } from './app/utils/browserHelpers'
import { ErrorFallback } from './features/components/errors/ErrorFallback'
import { PrivateRoutes } from './PrivateRoutes'
import { Loader } from './features/components/Loader'
import { Box, 
    useMediaQuery, 
    useTheme 
} from '@material-ui/core'
import { DrawerMenu } from './features/components/navigation/DrawerMenu'

const ErrorBoundaryComponent = React.lazy(() => import('./features/components/errors/ErrorBoundary'))

interface ISafeMountProps {
    userProfile: IUseProfileHook
    isUserAuthenticated: boolean
    isUserAuthenticationChecked: boolean
}

export const SafeMount = (props: ISafeMountProps) => {
    const theme = useTheme()
    const isLg = useMediaQuery(theme.breakpoints.up('lg'), {noSsr: true})

    // This is dumb but I'm tired and just want the code to work.
    // So instead of refactoring it like I should I'm just throwing a dummy function in.
    // If you see this then please fix it.
    const toggleDrawer = (_open: boolean) => (
        _event: React.KeyboardEvent | React.MouseEvent,
      ) => {
        return
      }

    return <>
        { !isSafari() || getSafariVersion() > 15 ?
        <Suspense fallback={<Loader />}>
            <ErrorBoundaryComponent FallbackComponent={ErrorFallback}>
                <Box display={isLg ? 'flex' : 'block'}>
                    <PrivateRoutes 
                        isUserAuthenticated={props.isUserAuthenticated}
                        isUserAuthenticationChecked={props.isUserAuthenticationChecked}
                    />
                    {/* Hacky. Throwing a function I don't need in. */}
                    <DrawerMenu open={isLg}
                        toggleDrawer={toggleDrawer}
                    />
                </Box>
            </ErrorBoundaryComponent>
        </Suspense> :
        <Box display='flex'>
            <PrivateRoutes 
                isUserAuthenticated={props.isUserAuthenticated}
                isUserAuthenticationChecked={props.isUserAuthenticationChecked}
            />
            <DrawerMenu open={isLg}
                toggleDrawer={toggleDrawer}
            />
        </Box>
        
    }
    </>
}