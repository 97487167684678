import { Suspense } from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import { Loader } from '../Loader'

type PrivateRouteParams = RouteProps & {
    component: React.FunctionComponent<RouteComponentProps>
    authed: Boolean
}

export const PrivateRoute = ({component: Component, authed, ...rest}: PrivateRouteParams) => {
    return (
        <Route 
            {...rest}
            render={(props) => authed === true
                ? <Suspense fallback={<Loader />}>
                    <Component {...props} />
                    </Suspense>
                : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
        />
    )
}
