import { Box, Button, Divider, Fade, makeStyles, Paper, Typography } from '@material-ui/core'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import cookieIcon from '../../../assets/svg/cookie-bite-icon.svg'

const useStyles = makeStyles((theme) => ({
	cookieConsent: {
		position: 'fixed',
		zIndex: 2000,
		bottom: 0,
		width: '100%',
		height: '9em',
        background: 'rgba(0, 0, 0, .75)',
        color: 'rgba(255, 255, 255, 1)'
	},
    cookieConsentIcon: {
        fontSize: '1em',
        width: '2em',
        color: 'rgba(255, 255, 255, 1)',
    },
    cookieConsentHeader: {
        fontSize: '1.5em',
        margin: '.25em',
        color: 'rgba(255, 255, 255, 1)',
    }, 
    cookieConsentMessage: {
        fontSize: '.75em',
        flexBasis: '60%',
        margin: '.25em',
        color: 'rgba(255, 255, 255, 1)',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1em'
        }
    },
    cookieConsentButton: {
        margin: '.25em',
        fontWeight: 'bold',
        color: 'rgba(255, 255, 255, 1)',
        backgroundColor: 'rgba(0, 0, 0, .9)',
        border: '2px solid rgba(255, 255, 255, 1)',
        '&:hover': {
            backgroundColor: 'rgba(75, 75, 75, .9)'
        }
    },
    cookieConsentDivider: {
        backgroundColor: 'rgba(255, 255, 255, .4)'
    }
}))

export const CookieConsent = () => {
    const [acceptedState, setAcceptedState] = useLocalStorage('atj-user-cookie-consent', false)

    const classes = useStyles()

    const setCookieConsentAccepted = () => {
        setAcceptedState(true)
    }

    return (
        <Fade in={!acceptedState}>
            <Box 
                component={Paper} 
                display="flex" 
                flexDirection="column"
                className={classes.cookieConsent}>
                    <Box 
                        m={1}
                        display="flex"
                        justifyContent="center">
                            <img 
                                width={'32px'}
                                height={'40px'}
                                className={classes.cookieConsentIcon}
                                src={cookieIcon} 
                                alt={'cookie-icon-left'}/>
                            <Typography 
                                variant={'h3'} 
                                className={classes.cookieConsentHeader}>Cookies!</Typography>
                            <img 
                                width={'32px'}
                                height={'40px'}
                                className={classes.cookieConsentIcon}
                                src={cookieIcon} 
                                alt={'cookie-icon-right'}/>
                    </Box>
                <Divider className={classes.cookieConsentDivider}/>
                <Box 
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-around"
                    >
                    <Typography 
                        className={classes.cookieConsentMessage}>This site uses cookies for an enhanced user experience. By using this site you agree to the use of cookies.</Typography>
                    <Divider 
                        orientation="vertical" 
                        className={classes.cookieConsentDivider}/>
                    <Button 
                        className={classes.cookieConsentButton}
                        variant={'contained'}
                        onClick={setCookieConsentAccepted}>Got it!</Button>
                </Box>
            </Box>
        </Fade>
    )
}