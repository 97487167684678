import { AxiosError, AxiosResponse } from 'axios'
import { checklistId, IUserChecklist } from '../../features/pages/tools/types'
import { ModuleLogger } from '../../services/moduleLogger'
import { IPetInfoTagResponse, PetId } from '../types/types'
import { defaultApiResponse } from '../utils/constants'
import { instance } from './axiosConfig'
import { IHttpError } from './types'
import { RequestBuilder } from './base'

const utilitiesLogger = new ModuleLogger()

type checklistField = 'checkbox' | 'custom'

type trigger = 'completion'

type triggerTagType = 'likes' | 'dislikes' | 'skills'

interface IChecklistTrigger {
    triggerType: trigger
    triggerTagType: triggerTagType
    triggerTagName: string
}

interface ISaveChecklistItem {
    id?: string
    fieldType: checklistField
    order: number
    weight: number
    text: string
    hasTrigger: boolean
    checklistTrigger: IChecklistTrigger
}

interface ISaveChecklistSection {
    id?: string
    header: string
    checklistItems: ISaveChecklistItem[]
    order: number
}

export interface ISaveChecklistPayload {
    id?: number
    title: string
    description: string
    reference: string
    threshold: number
    checklistSections: ISaveChecklistSection[]
    tags: any[]
}

interface ISaveChecklistResponsePayload {
    id: number
    title: string
    description: string
    reference: string
    isPublished: boolean
    threshold: number
    checklistSections: ISaveChecklistSection[]
    tags: IInfoTag
}

type SaveChecklistResponse = ISaveChecklistResponsePayload

interface IUpdatePayload {
    updatePayload: ISaveChecklistPayload
}

type UpdateChecklistResponse = ISaveChecklistPayload

interface IUpdateChecklistErrorResponse extends IHttpError {
    error: string
}

interface ISaveChecklistError extends IHttpError {
    error: string
}

export const saveNewChecklist = async (payload: ISaveChecklistPayload): Promise<AxiosResponse<SaveChecklistResponse|ISaveChecklistError>> => {
	const url = `${process.env.REACT_APP_API_URL}/user/utilities/checklists/new`
 
	try {
		const response: AxiosResponse<SaveChecklistResponse> = await instance.post(url, payload)
		return response
	} catch (error) {
		const err = error as AxiosError<ISaveChecklistError>
		utilitiesLogger.logError(err)
		if(err.response ){
			err.response.data.error = 'An error occurred saving checklist data.'
		}

        return err.response ?? {} as AxiosResponse<ISaveChecklistError>
	}
}

export const updateChecklist = async (checklistId: number, payload: IUpdatePayload): Promise<AxiosResponse<UpdateChecklistResponse|IUpdateChecklistErrorResponse>> => {
    const url = `${process.env.REACT_APP_API_URL}/user/utilities/checklists/${checklistId}/edit`

    try {
        const response: AxiosResponse<UpdateChecklistResponse> = await instance.put(url, payload)
        return response
    } catch (error) {
        const err = error as AxiosError<IUpdateChecklistErrorResponse>
        utilitiesLogger.logError(err)
        if (err.response) {
            err.response.data.error = 'An error occurred updating checklist data.'
        }

        return err.response ?? {} as AxiosResponse<IUpdateChecklistErrorResponse>
    }
}

interface IPetChecklist {
    title: string
    description: string
    id: number
    isPublished: boolean
    tags: IPetInfoTagResponse[]
    userName: string
}
// Can attempt data/metadata pattern
export type GetChecklistsSuccessResponse = IUserChecklist[]

export interface IGetChecklistsErrorResponse extends IHttpError {
    hasError: boolean
    error: string
}

export function isHttpError(object: any): object is IHttpError {
    return 'error' in object
}

export const requestGetUserChecklists = async (): Promise<AxiosResponse<GetChecklistsSuccessResponse|IGetChecklistsErrorResponse>> => {
    const url = `${process.env.REACT_APP_API_URL}/user/utilities/checklists`

    try {
        const response: AxiosResponse<GetChecklistsSuccessResponse> = await instance.get(url)
        return response ?? defaultApiResponse
    } catch (error) {
        const err = error as AxiosError<IGetChecklistsErrorResponse>
        utilitiesLogger.logError(err)
        if(err.response) {
            err.response.data.hasError = true
            err.response.data.error = 'An error occurred fetching checklist data'
        }

        return err.response ?? {} as AxiosResponse<IGetChecklistsErrorResponse>
    }
}

interface IChecklistItem {
    fieldType: checklistField
    order: number
    weight: number
    text: string
    hasTrigger: boolean
    checklistTrigger: IChecklistTrigger
}

interface IChecklistSection {
    header: string
    checklistItems: IChecklistItem[]
    order: number
}

interface IInfoTag {
    id?: number
    text: string
    infoTagTypeId: number
}

interface IChecklistDataTemplate {
    id: number
    title: string
    template: IChecklistSection[]
    description: string
    reference: string
    isPublished: boolean
    creatorId: number
    threshold: number
    tags: IInfoTag[]
}

export type GetChecklistDataSuccessResponse = IChecklistDataTemplate

export interface IGetChecklistDataErrorResponse extends IHttpError {
   hasError: boolean
   error: string 
}

export const getUserChecklistData = async (checklistId: number): Promise<AxiosResponse<GetChecklistDataSuccessResponse|IGetChecklistDataErrorResponse>> => {
    const url = `${process.env.REACT_APP_API_URL}/user/utilities/checklists/${checklistId}`

    try {
        const response: AxiosResponse<GetChecklistDataSuccessResponse> = await instance.get(url)
        return response
    } catch (error) {
        const err = error as AxiosError<IGetChecklistDataErrorResponse>
        utilitiesLogger.logError(err)
        if(err.response) {
            err.response.data.hasError = true
            err.response.data.error = 'An error occurred fetching checklist data'
        }

        return err.response ?? {} as AxiosResponse<IGetChecklistDataErrorResponse>
    }
}

export type GetAvailableUserChecklistsSuccessResponse = IPetChecklist[]

export interface IGetAvailableUserChecklistsErrorResponse extends IHttpError {
   hasError: boolean
   error: string 
}

export const getAvailablePetChecklistsData = async (petId: number): Promise<AxiosResponse<GetAvailableUserChecklistsSuccessResponse|IGetAvailableUserChecklistsErrorResponse>> => {
    const url = `${process.env.REACT_APP_API_URL}/user/pet/${petId}/utilities/checklists`

    try {
        const response: AxiosResponse<GetAvailableUserChecklistsSuccessResponse> = await instance.get(url)
        return response ?? defaultApiResponse
    } catch (error) {
        const err = error as AxiosError<IGetAvailableUserChecklistsErrorResponse>
        utilitiesLogger.logError(err)
        if(err.response) {
            err.response.data.hasError = true
            err.response.data.error = 'An error occurred fetching checklist data'
        }

        return err.response ?? {} as AxiosResponse<IGetAvailableUserChecklistsErrorResponse>
    }

}

interface IAddChecklistToPetPayload {
    checklistId: number
}

// remove unneeded any

export const addChecklistToPet = async (petId: number, payload: IAddChecklistToPetPayload): Promise<AxiosResponse<any>> => {
    const url = `${process.env.REACT_APP_API_URL}/user/pet/${petId}/utilities/checklists`

    try {
        const response: AxiosResponse<any> = await instance.post(url, payload)
        return response
    } catch (error) {
        const err = error as AxiosError<any>
        utilitiesLogger.logError(err)
        if (err.response) {
            err.response.data.hasError = true
            err.response.data.error = 'Unable to add checklist to pet'
        }

        return err.response ?? {} as AxiosResponse<any>
    }
}

interface IGetChecklistItemResponse {
    id: string
    order: number
    weight: number
    checked: boolean
    text: string
    fieldType: checklistField
    customText?: string
    trigger?: IChecklistTrigger
  }
  
export interface IGetPetChecklistSectionResponse {
    id: string
    header: string
    order: number
    checklistItems: IGetChecklistItemResponse[]
  }
  
export type PetChecklistResponse = {
    id: number
    title: string
    template: IGetPetChecklistSectionResponse[]
    description: string
    threshold: number
    originalChecklistId: number
}

export interface IPetChecklistErrorResponse extends IHttpError {
    hasError: boolean
    error: string
}

export const getPetChecklistDataById = async (petId: number, petChecklistId: number): Promise<AxiosResponse<PetChecklistResponse|IPetChecklistErrorResponse>> => {
    const url = `${process.env.REACT_APP_API_URL}/user/pet/${petId}/utilities/checklists/${petChecklistId}` 

    try {
        const response: AxiosResponse<PetChecklistResponse> = await instance.get(url)
        return response ?? defaultApiResponse
    } catch (error) {
        const err = error as AxiosError<IPetChecklistErrorResponse>
        utilitiesLogger.logError(err)
        if (err.response) {
            err.response.data.hasError = true
            err.response.data.error = 'Unable to add checklist to pet'
        }

        return err.response ?? {} as AxiosResponse<IPetChecklistErrorResponse>
    }
}

interface IUpdatePetChecklistPayload {
    checked: boolean
    checklistSectionId: string
    checklistItemId: string
}

interface IUpdatePetChecklistCustomFieldPayload {
    fieldValue: string
    checklistSectionId: string
    checklistItemId: string
}

type UpdatePetChecklistCustomFieldResponse = {
    id: number
    title: string
    template: any[]
    description: string
    threshold: number
    originalChecklistId: checklistId
}

interface IUpdatePetChecklistCustomFieldErrorResponse extends IHttpError {
    error: string
}

export const updatePetChecklistCustomField = async (petId: number, petChecklistId: number, payload: IUpdatePetChecklistCustomFieldPayload): Promise<AxiosResponse<UpdatePetChecklistCustomFieldResponse|IUpdatePetChecklistCustomFieldErrorResponse>> => {
    const url = `${process.env.REACT_APP_API_URL}/user/pet/${petId}/utilities/checklists/${petChecklistId}/custom_text`

    try {
        const response: AxiosResponse<UpdatePetChecklistCustomFieldResponse> = await instance.put(url, payload)
        return response
    } catch (error) {
        const err = error as AxiosError<IUpdatePetChecklistCustomFieldErrorResponse>
        utilitiesLogger.logError(err)
        if (err.response) {
            err.response.data.error = 'Error updating check item custom field'
        }

        return err.response ?? {} as AxiosResponse<IUpdatePetChecklistCustomFieldErrorResponse>
    }
}

type RefreshPetChecklistResponse = {
    id: number
    title: string
    template: IGetPetChecklistSectionResponse[]
    description: string
    threshold: number
    originalChecklistId: number
}

interface IRefreshPetChecklistErrorResponse extends IHttpError {
    error: string
}

export const refreshPetChecklist = async (petId: number, petChecklistId: number): Promise<AxiosResponse<RefreshPetChecklistResponse|IRefreshPetChecklistErrorResponse>> => {
    const url = `${process.env.REACT_APP_API_URL}/user/pet/${petId}/utilities/checklists/${petChecklistId}/reset_checklist`

    try {
        const response: AxiosResponse<RefreshPetChecklistResponse> = await instance.put(url, {})
        return response
    } catch (error) {
        const err = error as AxiosError<IRefreshPetChecklistErrorResponse>
        utilitiesLogger.logError(err)
        if (err.response) {
            err.response.data.error = 'Error resetting checklist'
        }

        return err.response ?? {} as AxiosResponse<IRefreshPetChecklistErrorResponse>
    }
}

export const updatePetChecklistItem = async (petId: number, petChecklistId: number, payload: IUpdatePetChecklistPayload): Promise<AxiosResponse<any>> => {
    const url = `${process.env.REACT_APP_API_URL}/user/pet/${petId}/utilities/checklists/${petChecklistId}`

    try {
        const response: AxiosResponse<any> = await instance.put(url, payload)
        return response
    } catch (error) {
        const err = error as AxiosError<any>
        utilitiesLogger.logError(err)
        if (err.response) {
            err.response.data.hasError = true
            err.response.data.error = 'Error saving check item'
        }

        return err.response ?? {} as AxiosResponse<any>
    }
}

interface IPublishChecklistPayload {
    checklistId: number
}

type PublishChecklistResponse = {
    published: boolean
}

interface IPublishChecklistErrorResponse extends IHttpError {
    error: string
}

export const publishChecklistRequest = async (payload: IPublishChecklistPayload): Promise<AxiosResponse<PublishChecklistResponse|IPublishChecklistErrorResponse>> => {
    const url = `${process.env.REACT_APP_API_URL}/user/utilities/checklists/publish`

    try {
        const response: AxiosResponse<PublishChecklistResponse> = await instance.put(url, payload)
        return response
    } catch (error) {
        const err = error as AxiosError<IPublishChecklistErrorResponse>
        utilitiesLogger.logError(err)
        if (err.response) {
            err.response.data.error = 'Error publishing checklist'
        }

        return err.response ?? {} as AxiosResponse<IPublishChecklistErrorResponse>
    }
}

type GetCommunitySuccessResponse = IUserChecklist[]

interface IGetCommunityChecklistsErrorResponse extends IHttpError {
    error: string
}

export const requestCommunityChecklists = async (): Promise<AxiosResponse<GetCommunitySuccessResponse|IGetCommunityChecklistsErrorResponse>> => {
    const url = `${process.env.REACT_APP_API_URL}/user/utilities/community/checklists`

    try {
        const response: AxiosResponse<GetCommunitySuccessResponse> = await instance.get(url)
        return response
    } catch (error) {
        const err = error as AxiosError<IGetCommunityChecklistsErrorResponse>
        utilitiesLogger.logError(err)
        if (err.response) {
            err.response.data.error = 'Error requesting community checklists'
        }

        return err.response ?? {} as AxiosResponse<IGetCommunityChecklistsErrorResponse>
    }
}

interface ISaveUserToChecklistRequestPayload {
    checklistId: checklistId
}

type SaveChecklistToUserResponse = {
    userId: number
    checklistId: checklistId
}

interface ISaveChecklistToUserErrorResponse extends IHttpError {
    error: string
}

export const saveUserToChecklistRequest = async (payload: ISaveUserToChecklistRequestPayload): Promise<AxiosResponse<SaveChecklistToUserResponse|ISaveChecklistToUserErrorResponse>> => {
    const url = `${process.env.REACT_APP_API_URL}/user/utilities/checklists/add`

    try {
        const response: AxiosResponse<SaveChecklistToUserResponse> = await instance.post(url, payload)
        return response
    } catch (error) {
        const err = error as AxiosError<ISaveChecklistToUserErrorResponse>
        utilitiesLogger.logError(err)
        if (err.response) {
            err.response.data.error = 'Error saving checklist to user'
        }

        return err.response ?? {} as AxiosResponse<ISaveChecklistToUserErrorResponse>
    }
}

interface IDeleteChecklistFromPetResponse {
    deleted: boolean
}

interface IDeleteChecklistFromPetErrorResponse {
    error: string
}

export const deleteChecklistFromPetRequest = async (petId: PetId, checklistId: number): Promise<AxiosResponse<IDeleteChecklistFromPetResponse | IDeleteChecklistFromPetErrorResponse>> => {
    const urlSuffix = `/user/pet/${petId}/utilities/checklists/${checklistId}`
    
    const request = new RequestBuilder<IDeleteChecklistFromPetResponse, IDeleteChecklistFromPetErrorResponse>()
        .setUrl(urlSuffix)
        .setRequestType('delete')
        .setLogger(utilitiesLogger)
        .setErrorString('An error has occurred deleting checklist from pet')

    const response = await request.send()

    return response
}