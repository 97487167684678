import { createContext, Dispatch, SetStateAction } from 'react'
import { IUserIntegrations, IUserPet } from '../app/types/types'

export interface IUserProfile {
    isAuthenticated: boolean
    authenticationChecked: boolean
    isPremium: boolean
    premiumLevel: number
    checklistCount: number
    pets: IUserPet[]
    integrations: IUserIntegrations
}

export interface IUseProfileHook {
    userProfile: IUserProfile
    loading: boolean
    setAuthenticated: Dispatch<SetStateAction<boolean>>
    setLoading: Dispatch<SetStateAction<boolean>>
    loggedIn: () => boolean
    refreshProfileCredentials: () => void
    updateIntegrations: (key: keyof IUserIntegrations, value: boolean) => void
}

const defaultContextValues: IUseProfileHook = {
    userProfile: {
        isAuthenticated: false,
        authenticationChecked: false,
        isPremium: false,
        premiumLevel: 0,
        checklistCount: 0,
        pets: [],
        integrations: {
            youtube_import: {
                enabled: false
            }
        }
    },
    loading: true,
    setAuthenticated: () => null,
    setLoading: () => null,
    loggedIn: () => false,
    refreshProfileCredentials: () => null,
    updateIntegrations: () => null
}

export const UserAuthenticationContext = createContext(defaultContextValues)