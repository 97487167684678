import { Box, makeStyles } from '@material-ui/core'
import PetsIcon from '@material-ui/icons/Pets'

const useStyles = makeStyles((theme) => ({
	placeholderContainer: {
		width: theme.spacing(20),
		height: theme.spacing(20),
		borderRadius: 4,
		overflow: 'hidden',
		background: '#409b63',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: '1em',
		boxShadow: '0 0 2px #ccc'
	},
	petsIcon: {
		fontSize: 140,
		color: '#f3f4e4',
	},
}))

interface IPetAvatarPlaceholderProps {
	className?: string | undefined
	iconClassName?: string | undefined
}

export const PetAvatarPlaceholder = (props: IPetAvatarPlaceholderProps) => {
    const classes = useStyles()

    return (
        <Box 
        className={props.className ?? classes.placeholderContainer}>
            <PetsIcon className={props.iconClassName ?? classes.petsIcon}/>
        </Box>
    )
}