import axios, { AxiosRequestConfig } from 'axios'
import { refreshUserAuthentication } from './signupApi'

interface RequestConfig extends AxiosRequestConfig {
	_retry: boolean
}

export const instance = axios.create({
	withCredentials: true,
	baseURL: process.env.REACT_APP_API_URL
})

instance.interceptors.response.use(
	(response) => {
		return response
	},
	async (error) => {
		const originalConfig: RequestConfig = error.config
		const routeNameArr = originalConfig.url?.split('/') ?? []
		const suffix = routeNameArr[routeNameArr.length - 1]
		 
	  if (error?.response?.status === 401 && !originalConfig?._retry && suffix !== 'refresh') {
		originalConfig._retry = true
		
		await refreshUserAuthentication()
		  
	  	return instance(originalConfig)
	  }

	  if (error.response.status === 400) {
		return Promise.reject(error)
	  }
	}
  )

