import { Grid, makeStyles, Typography } from '@material-ui/core'
import PetsIcon from '@material-ui/icons/Pets'

const useStyles = makeStyles((_theme) => ({
    container: {
        margin: '0 auto'
    },
    petsIcon: {
        color: '#1bca68',
    },
    whiteShadow: {
        textShadow: '1px 1px 1px #FFF'
    }
}))

interface IPetIconRowProps {
    text: string
    shadow?: boolean
}

export const PetIconRow = (props: IPetIconRowProps) => {
    const classes = useStyles()
    const { text, shadow } = props

    return <Grid container
        className={classes.container}
        >
            <Grid item xs={3}>
                <PetsIcon className={classes.petsIcon}/>
            </Grid>
            <Grid item xs={9}>
                <Typography className={ shadow ? classes.whiteShadow : '' } align={'left'}>{text}</Typography>
            </Grid>
    </Grid>
}