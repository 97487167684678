import { useContext } from 'react'
import { UserAuthenticationContext } from '../../../contexts/UserAuthenticationContext'
import { UserLoggedInLinks } from './UserLoggedInLinks'
import { UserLoggedOutLinks } from './UserLoggedOutLinks'
import Skeleton from '@material-ui/lab/Skeleton'

export const NavbarLinks = () => {
    const userContext = useContext(UserAuthenticationContext)

    const userAuthenticated = userContext?.userProfile?.isAuthenticated ?? false

    return (
        <>
            { userContext?.loading ?
            <Skeleton 
                variant='text' 
                width='100%' 
                height='2em'/> :
                userAuthenticated ?
                <UserLoggedInLinks /> :
                <UserLoggedOutLinks />
            }
        </>
    )
}