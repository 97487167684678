import { Box } from '@material-ui/core'
import { Switch, Route, Redirect } from 'react-router-dom'
import { BackdropLoader } from './features/components/BackdropLoader'
import { IUseProfileHook } from './contexts/UserAuthenticationContext'
import { SafeMount } from './SafeMount'
import { Suspense, lazy } from 'react'
import { Loader } from './features/components/Loader'

const HomePage = lazy(() => import('./features/pages/home/HomePage'))
const PublicRouter = lazy(() => import('./features/pages/public/PublicRouter'))
const SignupForm = lazy(() => import('./features/pages/signup/SignupForm'))
const LoginForm = lazy(() => import('./features/pages/login/LoginForm'))
const PrivacyPolicy = lazy(() => import('./features/pages/privacy/PrivacyPolicy'))
const TermsAndConditions = lazy(() => import('./features/pages/privacy/TermsAndConditions'))
const Attributions = lazy(() => import('./features/pages/attributions/Attributions'))
const Tutorials = lazy(() => import('./features/pages/global/documentation/tutorials/Tutorials'))
const ProductDisplay = lazy(() => import('./features/pages/premium/ProductDisplay'))
const SuccessfulOrder = lazy(() => import('./features/pages/premium/SuccessfulOrder'))
const UnsuccessfulOrder = lazy(() => import('./features/pages/premium/UnsuccessfulOrder'))
const ContactUs = lazy(() => import('./features/pages/social/ContactUs'))
const FacebookDataDeletion = lazy(() => import('./features/pages/social/facebook/FacebookDataDeletion'))
const PasswordResetRequest = lazy(() => import('./features/pages/passwordReset/PasswordResetRequest'))
const PasswordResetForm = lazy(() => import('./features/pages/passwordReset/PasswordResetForm'))
const PasswordResetSubmitted = lazy(() => import('./features/pages/passwordReset/PasswordResetSubmitted'))
const PasswordResetRequestSuccess = lazy(() => import('./features/pages/passwordReset/PasswordResetRequestSuccess'))
interface IAppRouterProps {
    userProfile: IUseProfileHook
    isUserAuthenticated: boolean
    isUserAuthenticationChecked: boolean
}

export const AppRouter = (props: IAppRouterProps) => {
    const { userProfile, isUserAuthenticated, isUserAuthenticationChecked } = props

    return 	<Box
    flexGrow="1"
    display="flex"
    flexDirection="column"
>
    <Switch>
        <Route 
            exact 
            path={['/', '/home']}>
                <Suspense fallback={<Loader />}>
                    <HomePage />
                </Suspense>
        </Route>
        <Route
            path="/public">
                <Suspense fallback={<Loader />}>
                    <PublicRouter />
                </Suspense>
            </Route>
        <Route 
            exact 
            path="/signup">
                <Suspense fallback={<Loader />}>
                    <SignupForm />
                </Suspense>
        </Route>
        <Route 
            exact 
            path="/login">
                <Suspense fallback={<Loader />}>
                    <LoginForm />
                </Suspense>
        </Route>
        <Route
            exact
            path="/privacy">
                <Suspense fallback={<Loader />}>
                    <PrivacyPolicy/>
                </Suspense>
        </Route>
        <Route
            exact
            path="/terms">
                <Suspense fallback={<Loader />}>
                    <TermsAndConditions/>
                </Suspense>
        </Route>
        <Route
            exact
            path="/attributions"
            >
                <Suspense fallback={<Loader />}>
                    <Attributions />
                </Suspense>
        </Route>
        <Route
            exact
            path="/tutorials"
            >
                <Suspense fallback={<Loader />}>
                    <Tutorials/>
                </Suspense>
        </Route>
        <Route
            exact
            path="/premium"
        >
            <Suspense fallback={<Loader />}>
                <ProductDisplay />
            </Suspense>
        </Route>
        <Route
            exact
            path="/premium/order_successful"
        >
            <Suspense fallback={<Loader />}>
                <SuccessfulOrder />
            </Suspense>
        </Route>
        <Route
            exact
            path="/premium/order_cancelled"
        >
            <Suspense fallback={<Loader />}>
                <UnsuccessfulOrder />
            </Suspense>
        </Route>
        <Route
            exact
            path="/contact">
                <Suspense fallback={<Loader />}>
                    <ContactUs />
                </Suspense>
        </Route>
        <Route
            path="/facebook_deletion_status"
        >
            <Suspense fallback={<Loader />}>
                <FacebookDataDeletion />
            </Suspense>
        </Route>
        <Route
            path="/password_reset_request"
        >
            <Suspense fallback={<Loader /> }>
                <PasswordResetRequest />
            </Suspense>
        </Route>
        <Route
            path="/password_reset"
        >
            <Suspense fallback={<Loader />}>
                <PasswordResetForm />
            </Suspense>
        </Route>
        <Route
            path="/password_reset_submitted"
        >
            <Suspense fallback={<Loader />}>
                <PasswordResetSubmitted />
            </Suspense>
        </Route>
        <Route
            path="/password_reset_success"
        >
            <Suspense fallback={<Loader /> }>
                <PasswordResetRequestSuccess />
            </Suspense>
        </Route>

        { userProfile?.loading ?
        <BackdropLoader loading={true} /> :
            <SafeMount 
                userProfile={userProfile} 
                isUserAuthenticated={isUserAuthenticated} 
                isUserAuthenticationChecked={isUserAuthenticationChecked}/>
        }
        <Redirect to="/" />
    </Switch>
</Box>
}