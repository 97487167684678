import { AppBar, Box, makeStyles, Toolbar } from '@material-ui/core'
import { Link, matchPath, RouteComponentProps, useHistory, 
    withRouter } from 'react-router-dom'
import { NavbarLinks } from './NavbarLinks'
import tsLogo from '../../../assets/images/logo.png'
import { SubnavbarButton } from './SubnavbarButton'

import NoteAddIcon from '@material-ui/icons/NoteAdd'
import PetsIcon from '@material-ui/icons/Pets'
import FormatListBulleted from '@material-ui/icons/FormatListBulleted'
import SettingsIcon from '@material-ui/icons/Settings'
import BarChartRoundedIcon from '@material-ui/icons/BarChartRounded'
import { useContext } from 'react'
import { UserAuthenticationContext } from '../../../contexts/UserAuthenticationContext'

const useStyles = makeStyles((theme) => ({
	rightNav: {
		maxWidth: '10em',
		[theme.breakpoints.up('sm')]: {
			maxWidth: '15em'
		},
	},
	navBar: {
        zIndex: 1000,
		backgroundColor: '#e3f4ef',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0),0px 4px 5px 0px rgba(0,0,0,0.14),0px 0px 0px 0px rgba(0,0,0,0.12)',
		color: '#1B422A',
		'& a': {
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'underline'
			}
		},
        [theme.breakpoints.down('md')]: {
            zIndex: 1000
        }
	},
    navBarSticky: {
        zIndex: 1000,
		backgroundColor: '#e3f4ef',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0),0px 4px 5px 0px rgba(0,0,0,0.14),0px 0px 0px 0px rgba(0,0,0,0.12)',
		color: '#1B422A',
		'& a': {
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'underline'
			}
		},
        [theme.breakpoints.up('lg')]:
        {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            paddingRight: '240px'
        }

    },
	navBarTypography: {
		textDecoration: 'none',
		color: 'inherit',
        maxWidth: '100%',
        textAlign: 'center',
        display: 'flex',
	},
    atjLogo: {
        height: '2.3em',
        alignSelf: 'center'
    },
    subNavigationToolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'sticky',
        top: '0px',
        [theme.breakpoints.up('lg')]: {
            width: '500px'
        }
    }
    
}))

interface MatchParams {
    petId: string
}

interface INavbarProps extends RouteComponentProps<MatchParams> {}

const NavbarComponent = (_props: INavbarProps) => {
    const profile = useContext(UserAuthenticationContext)
    const history = useHistory()
    const match = matchPath(history.location.pathname, {
        path: '/dashboard/pet/:petId',
        exact: false,
        strict: false
    })

    type regexOptions = 'petDetailsRegex' | 'petNotesRegex' | 'skillsPageRegex' | 'metricsRegex' | 'settingsRegex'

    const regexes: {[key in regexOptions]: RegExp } = {
		petDetailsRegex: new RegExp('^/dashboard/pet/(\\d+)$', 'g'),
		petNotesRegex: new RegExp('^/dashboard/pet/(\\d+)/notes$'),
		skillsPageRegex: new RegExp('^/dashboard/pet/(\\d+)/skills'),
		metricsRegex: new RegExp('^/dashboard/pet/(\\d+)/metrics'),
		settingsRegex: new RegExp('^/dashboard/pet/(\\d+)/settings')
	}

    // @ts-ignore
    const foundPetId = match?.params.petId
    const petData = profile.userProfile.pets.find(f => f.id === Number(foundPetId))

    const classes = useStyles()

    return	(
        <>
        <AppBar 
            elevation={foundPetId ? 0 : 4}
            position="static" 
            className={classes.navBar}>
            <Toolbar 
                component={Box}
                display="flex"
                justifyContent="space-between">
                <Box>
                    <Link
                        className={classes.navBarTypography}
                        to={'/home'}>
                            <img 
                            className={classes.atjLogo}
                            src={tsLogo} 
                            alt={'home'}
                            width={'160px'}
                            height={'37px'}/>
                    </Link>
                </Box>
                <Box 
                    className={classes.rightNav}
                    display="flex"
                    justifyContent="flex-end"
                    flexBasis="25%"
                    flexGrow="1">
                        <NavbarLinks />
                </Box>
            </Toolbar>
        </AppBar>
        { foundPetId ? 
        <AppBar 
            position='sticky'
            className={classes.navBarSticky}>
            <Toolbar className={classes.subNavigationToolbar}>
                <SubnavbarButton 
                    selected={regexes.petDetailsRegex.test(history.location.pathname)}
                    label='Details'
                    routePath={`/dashboard/pet/${foundPetId}`}
                    icon={<PetsIcon />}
                />
                <SubnavbarButton 
                    selected={regexes.petNotesRegex.test(history.location.pathname)}
                    label='Notes'
                    routePath={`/dashboard/pet/${foundPetId}/notes`}
                    icon={<NoteAddIcon />}
                />
                <SubnavbarButton 
                    selected={regexes.skillsPageRegex.test(history.location.pathname)}
                    label='Skills'
                    routePath={`/dashboard/pet/${foundPetId}/skills`}
                    icon={<FormatListBulleted />}
                />
                <SubnavbarButton 
                    selected={regexes.metricsRegex.test(history.location.pathname)}
                    label='Metrics'
                    routePath={`/dashboard/pet/${foundPetId}/metrics`}
                    icon={<BarChartRoundedIcon />}
                />
                {/* ONLY should appear if user is in possession of pet.
                May need to use context to verify. */}
                {
                    petData != null && petData.permissionLevel === 0 ?
                <SubnavbarButton 
                    selected={regexes.settingsRegex.test(history.location.pathname)}
                    label='Settings'
                    routePath={`/dashboard/pet/${foundPetId}/settings`}
                    icon={<SettingsIcon />}
                /> : null }
                {/* Second Toolbar for pet {foundPetId} */}
            </Toolbar>
        </AppBar> : null }
        </>
    )
}

export const Navbar = withRouter(NavbarComponent)