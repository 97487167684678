import { useEffect, useState } from 'react'
import { checkUserAuthentication } from '../app/api/signupApi'
import { IUseProfileHook, IUserProfile } from '../contexts/UserAuthenticationContext'
import { useHistory } from 'react-router-dom'
import { isHttpError } from '../app/api/toolsApi'
import { exponentialTimeout, IExponentialTimeoutReturnProps } from '../app/utils/timeHelpers'
import { IUserIntegrations, IUserPet } from '../app/types/types'
import { updateUserDeviceSubscription } from '../app/api/dashboardApi'
import { useCookie } from './useCookie'
import { urlBase64ToUint8Array } from '../app/utils/stringHelpers'

interface IUseProfileHookProps {
    triggerToast: (toastMessage: string) => void
}

export const useProfile = (props: IUseProfileHookProps) => {
    const { getCookie } = useCookie()
    const history = useHistory()
    const { triggerToast } = props

    const defaultIntegration: IUserIntegrations = {
        youtube_import: {
            enabled: false
        }
    }

    const [isAuthenticated, setAuthenticated] = useState<boolean>(false)
    const [authenticationChecked, setAuthenticationChecked] = useState<boolean>(false)
    const [isPremium, setIsPremium] = useState<boolean>(false)
    const [premiumLevel, setPremiumLevel] = useState<number>(0)
    const [ checklistCount, setChecklistCount] = useState<number>(0)
    const [ loading, setLoading ] = useState<boolean>(true)
    const [ pets, setUserPets ] = useState<IUserPet[]>([])
    const [integrations, setIntegrations] = useState<IUserIntegrations>(defaultIntegration)

    // Worth moving these to a constants file.
    const nonRedirectLocations = ['/', '/signup', '/login', '/privacy', '/facebook_deletion_status', '/password_reset_request', '/password_reset', '/password_reset_submitted', '/contact']

    const updatePushSubscription = () => {
        navigator.serviceWorker.ready
            .then((serviceWorkerRegistration) => {
                serviceWorkerRegistration.pushManager
                .getSubscription()
                .then(async (subscription) => {
                    if(subscription) {
                        // First, check for an existing subscription on the service worker.
                        const deviceMetadata = navigator ? navigator.userAgent : ''
                        const deviceCookie = getCookie('deviceKey')
                        const payload = {
                            deviceMetadata,
                            pushSubscription: subscription,
                            deviceKey: deviceCookie
                        }
                        
                        updateUserDeviceSubscription(payload)
    
                    } else if (Notification && Notification.permission === 'granted') {
                        // Follow-up, check the permissions on notifications.
                        const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY

                        let convertedVapidKey: Uint8Array
            
                        if (vapidPublicKey) {
                            convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey)
                            
                            return serviceWorkerRegistration.pushManager.subscribe({
                                userVisibleOnly: true,
                                applicationServerKey: convertedVapidKey
                            }).then(async (subscription: PushSubscription) => {
                                if(subscription) {
                                    const deviceMetadata = navigator ? navigator.userAgent : ''
                                    const deviceCookie = getCookie('deviceKey')
                                    const payload = {
                                        deviceMetadata,
                                        pushSubscription: subscription,
                                        deviceKey: deviceCookie
                                    }
                                    updateUserDeviceSubscription(payload)
                
                                    // Then set it on the screen or whatever for the given device.
                                } else {
                                    return
                                    // There should be a subscription here. So clearly there is something wrong.
                                }
                            })
                        }
                    }
                })
            })
    }

    useEffect(() => {
        const checkUserProfile = async () => {
            if(authenticationChecked){
                return
            }

            setLoading(true)

            // Make entire page load.
            const authenticationResponse = await checkUserAuthentication()
            const authenticationResponseData = authenticationResponse?.data ?? { error: 'an error occurred authenticating user' }
            if (isHttpError(authenticationResponseData)) {
                setAuthenticationChecked(true)
                setAuthenticated(false)
                // getting complex, abstract into a redirectmapper.
                if(!nonRedirectLocations.includes(history.location.pathname)
                && !(history?.location?.pathname ?? '').includes('/public')) {
                    history.push('/')
                }
            } else {
                setAuthenticationChecked(true)
                setAuthenticated(true)
                setIsPremium(authenticationResponseData.isPremium)
                setPremiumLevel(authenticationResponseData.premiumLevel)
                setChecklistCount(authenticationResponseData.checklistsCount)
                setUserPets(authenticationResponseData.pets)
                setIntegrations(authenticationResponseData.integrations)
                triggerToast('Logged in successfully')
                updatePushSubscription()
            }

            setLoading(false)
        }

        checkUserProfile()
    }, [])

    useEffect(() => {
        let returnProps: IExponentialTimeoutReturnProps

        const credentialsRetry = (authCheck: boolean) => {
            returnProps = exponentialTimeout(300000, async () => {
                if(authCheck) {
                    await refreshProfileCredentials()
                }
            })
        }

        credentialsRetry(isAuthenticated)

        return () => {
            returnProps.clear()
        }
    }, [isAuthenticated])

    const refreshProfileCredentials = async (): Promise<void> => {
        const authenticationResponse = await checkUserAuthentication()
        const authenticationResponseData = authenticationResponse.data
        if (isHttpError(authenticationResponseData)) {
            triggerToast('error refreshing profile credentials')
        } else {
            setAuthenticationChecked(true)
            setAuthenticated(true)
            setChecklistCount(authenticationResponseData.checklistsCount)
            setIsPremium(authenticationResponseData.isPremium)
            setPremiumLevel(authenticationResponseData.premiumLevel)
            updatePushSubscription()
        }

    }

    const updateIntegrations = (key: keyof IUserIntegrations, value: boolean) => {
        const payload = {
            enabled: value
        }

        setIntegrations(previousState => {
            previousState[key] = payload

            return previousState
        })
    }

    const userProfile: IUserProfile = {
        isAuthenticated,
        authenticationChecked,
        isPremium,
        premiumLevel,
        checklistCount,
        pets,
        integrations
    }

    const loggedIn = (): boolean => {
        return isAuthenticated && authenticationChecked
    }

    return {
        userProfile,
        loading,
        setAuthenticated,
        setLoading,
        loggedIn,
        refreshProfileCredentials,
        updateIntegrations
    } as IUseProfileHook
}