import { Box, Button, Icon, Typography, makeStyles } from '@material-ui/core'
import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles((_theme) => ({
    navbarButton: {
        minWidth: 60,
        maxWidth: 70,
        height: 70,
        '&:hover': {
            backgroundColor: '#409b63',
            color: '#e3f4ef'
        }
    },
    highlightedButton: {
        minWidth: 70,
        maxWidth: 80,
        height: 70,
        backgroundColor: '#409b63',
        color: '#e3f4ef',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#409b63',
            color: '#e3f4ef'
        }
    },
    buttonIcon: {
        width: 32,
        height: 32,
        alignSelf: 'center',
        color: 'inherit'
    },
    highlightedIcon: {
        width: 32,
        height: 32,
        alignSelf: 'center',
        color: 'inherit'
    },
    typographyLabel: {
        textTransform: 'capitalize',
        color: 'inherit'
    },
    highlightedLabel: {
        textTransform: 'capitalize',
        color: 'inherit'
    },
    buttonRoot: {
        transition: 'color .25s, background-color .25s'
    }
}))

interface ISubnavbarButtonProps {
    selected: boolean
    label: string
    icon: ReactNode
    routePath: string
}

export const SubnavbarButton = (props: ISubnavbarButtonProps) => {
    const { label, icon, routePath, selected} = props
    const classes = useStyles()

    return <Button 
        classes={{root: classes.buttonRoot}}
        className={selected ? classes.highlightedButton : classes.navbarButton}
        component={RouterLink}
        to={routePath}>
            <Box display='flex'
            flexDirection='column'>
                <Icon className={ selected ? classes.highlightedIcon : classes.buttonIcon}>
                    {icon}
                </Icon>
                <Typography className={selected ? classes.highlightedLabel : classes.typographyLabel} variant={'caption'}>{label}</Typography>
            </Box>
    </Button>
}