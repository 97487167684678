import { createContext } from 'react'

export interface IPremiumModalContextProps {
    open: boolean
    setOpen: (state: boolean) => void
}

const defaultContextValues: IPremiumModalContextProps = {
    open: false,
    setOpen: ( _state: boolean): void => { return }
}

export const PremiumModalContext = createContext(defaultContextValues)