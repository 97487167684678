import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { INoteGraphData, ITrainingSessionGraphData } from '../../features/pages/dashboard/pets/notes/types'
import { ModuleLogger } from '../../services/moduleLogger'
import { IPetInfoTag, IPetInfoTagResponse, PetId } from '../types/types'
import { defaultApiResponse } from '../utils/constants'
import { handleApiResponseError } from '../utils/responseHelpers'
import { instance } from './axiosConfig'
import { handleRequest, IRequestPayload, RequestBuilder } from './base'
import { IHttpError } from './types'
import { UnitType } from '../../features/components/input/UnitSelect'
import { IGraphData } from '../../features/pages/dashboard/pets/metrics/PetMetricGraphComponent'
import { IStatDefault } from '../../features/pages/dashboard/pets/metrics/PetMetrics'

const dashboardApiLogger = new ModuleLogger()

export const getCurrentUserPets = async () => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pets`

	const response = await instance.get(url)
	return response
}

interface ISaveNewPetSuccessResponse {
	id: number
	name: string
	imageUrl: string
}

interface ISaveNewPetErrorResponse extends IHttpError {
	error: string
}

export const saveNewPet = async (payload: FormData) => {
	const urlSuffix = '/user/profile/pets/new'
	const headers = { 'Content-Type': 'multipart/form-data' }

	const request = new RequestBuilder<ISaveNewPetSuccessResponse,ISaveNewPetErrorResponse>()
		.setUrl(urlSuffix)
		.setHeaders(headers)
		.setRequestType('post')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error has occurred saving pet')
		.setPayload(payload)

	const response = await request.send()

	return response
}

export const updatePetById = async (id: number, payload: any) => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/${id}`

	const response = await instance.put(url, payload, {
		headers: { 'Content-Type': 'multipart/form-data' }
	})

	return response
}

interface IPetUpdateImageResponse {
	imageUrl: string
}

interface IPetUpdateImageErrorResponse {
	error: string
}

export const updatePetImage = async (id: number, formData: FormData): Promise<AxiosResponse<IPetUpdateImageResponse|IPetUpdateImageErrorResponse>> => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pet/${id}/image`

	try {
		const response: AxiosResponse<IPetUpdateImageResponse> = await instance.put(url, formData)
		return response
	} catch (error) {
		const err = error as AxiosError<IPetUpdateImageErrorResponse>
		dashboardApiLogger.logError(err)
		if(err.response ){
			err.response.data.error = 'An error occurred updating pet image'
		}

        return err.response ?? {} as AxiosResponse<IPetUpdateImageErrorResponse>
	}
}

interface IPetUpsertTagResponse {
	tags: IPetInfoTagResponse[]
}

interface IPetUpsertTagErrorResponse {
	error: string
}

export const upsertPetTags = async (id: number, data: IPetInfoTag[]): Promise<AxiosResponse<IPetUpsertTagResponse|IPetUpsertTagErrorResponse>> => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pet/${id}/tags`

	try {
		const response: AxiosResponse<IPetUpsertTagResponse> = await instance.put(url, data)
		return response
	} catch (error) {
		const err = error as AxiosError<IPetUpsertTagErrorResponse>
		dashboardApiLogger.logError(err)
		if(err.response){
			err.response.data.error = 'An error occurred updating tag for pet'
		}

		return err.response ?? {} as AxiosResponse<IPetUpsertTagErrorResponse>
	}
}

interface IUpdatePetAboutTextPayload extends IRequestPayload {
	text: string
}

interface IUpdatePetAboutTextResponse {
	text: string
}

interface IUpdatePetAboutTextErrorResponse extends IHttpError {
	error: string
}

export const updatePetAboutText = async (id: number, payload: IUpdatePetAboutTextPayload): Promise<AxiosResponse<IUpdatePetAboutTextResponse|IUpdatePetAboutTextErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/about`

	const response = await handleRequest<IUpdatePetAboutTextResponse,IUpdatePetAboutTextErrorResponse>(
		urlSuffix, 
		'put', 
		dashboardApiLogger, 
		'An error occurred updating about text', 
		payload)

	return response
}

interface IUpdatePetBreedPayload extends IRequestPayload {
	breed: string
}

interface IUpdatePetBreedResponse {
	breed: string
}

interface IUpdatePetBreedErrorResponse extends IHttpError {
	error: string
}

export const updatePetBreed = async (id: number, payload: IUpdatePetBreedPayload): Promise<AxiosResponse<IUpdatePetBreedResponse|IUpdatePetBreedErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/breed`

	const response = await handleRequest<IUpdatePetBreedResponse,IUpdatePetBreedErrorResponse>(
		urlSuffix, 
		'put', 
		dashboardApiLogger, 
		'An error occurred updating breed', 
		payload)

	return response
}

interface IUpdatePetSexPayload extends IRequestPayload {
	sex: string
}

interface IUpdatePetSexResponse {
	sex: string
}

interface IUpdatePetSexErrorResponse extends IHttpError {
	error: string
}

export const updatePetSex = async (id: number, payload: IUpdatePetSexPayload): Promise<AxiosResponse<IUpdatePetSexResponse|IUpdatePetSexErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/sex`

	const response = await handleRequest<IUpdatePetSexResponse,IUpdatePetSexErrorResponse>(
		urlSuffix, 
		'put', 
		dashboardApiLogger, 
		'An error occurred updating sex', 
		payload)

	return response
}

interface IUpdatePetSpeciesPayload extends IRequestPayload {
	speciesText: string
}

interface IUpdatePetSpeciesResponse {
	species: string
}

interface IUpdatePetSpeciesErrorResponse extends IHttpError {
	error: string
}

export const updatePetSpecies = async (id: number, payload: IUpdatePetSpeciesPayload): Promise<AxiosResponse<IUpdatePetSpeciesResponse|IUpdatePetSpeciesErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/species`

	const response = await handleRequest<IUpdatePetSpeciesResponse,IUpdatePetSpeciesErrorResponse>(
		urlSuffix, 
		'put', 
		dashboardApiLogger, 
		'An error occurred updating species', 
		payload)

	return response
}

interface IUpdateDateOfBirthPayload {
	dateOfBirth: MaterialUiPickersDate
}

interface IUpdateDateOfBirthResponse {
	dateOfBirth: Date
}

interface IUpdateDateOfBirthErrorResponse {
	error: string
}

export const updatePetDateOfBirth = async (id: number, payload: IUpdateDateOfBirthPayload): Promise<AxiosResponse<IUpdateDateOfBirthResponse|IUpdateDateOfBirthErrorResponse>> => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pet/${id}/date_of_birth`

	try {
		const response: AxiosResponse<IUpdateDateOfBirthResponse> = await instance.put(url, payload)
		return response
	} catch (error) {
		const err = error as AxiosError<IUpdateDateOfBirthErrorResponse>
		dashboardApiLogger.logError(err)
		if (err.response) {
			err.response.data.error = 'An error occurred updating date of birth'
		}

		return err.response ?? {} as AxiosResponse<IUpdateDateOfBirthErrorResponse>
	}
}

interface IUpdateDateOfBirthExactPayload {
	dateOfBirthExact: boolean
}

interface IUpdateDateOfBirthExactResponse {
	dateOfBirthExact: boolean
}

interface IUpdateDateOfBirthExactErrorResponse {
	error: string
}

export const updatePetDateOfBirthExact = async (id: number, payload: IUpdateDateOfBirthExactPayload): Promise<AxiosResponse<IUpdateDateOfBirthExactResponse|IUpdateDateOfBirthExactErrorResponse>> => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pet/${id}/date_of_birth_exact`

	try {
		const response: AxiosResponse<IUpdateDateOfBirthExactResponse> = await instance.put(url, payload)
		return response
	} catch (error) {
		const err = error as AxiosError<IUpdateDateOfBirthExactErrorResponse>
		dashboardApiLogger.logError(err)
		if (err.response) {
			err.response.data.error = 'An error occurred updating pet date of birth exact box'
		}

		return err.response ?? {} as AxiosResponse<IUpdateDateOfBirthExactErrorResponse>
	}
}

interface IUpdatePetNamePayload {
	name: string
}

interface IUpdatePetNameResponse {
	name: string
}

interface IUpdatePetNameErrorResponse {
	error: string
}

export const updatePetName = async (id: number, payload: IUpdatePetNamePayload): Promise<AxiosResponse<IUpdatePetNameResponse|IUpdatePetNameErrorResponse>> => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pet/${id}/name`

	try {
		const response: AxiosResponse<IUpdatePetNameResponse> = await instance.put(url, payload)
		return response
	} catch (error) {
		const err = error as AxiosError<IUpdatePetNameErrorResponse>
		dashboardApiLogger.logError(err)
		if (err.response) {
			err.response.data.error = 'An error occurred updating pet date of birth exact box'
		}

		return err.response ?? {} as AxiosResponse<IUpdatePetNameErrorResponse>
	}
}

export const getPetById = async(petId: number) => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/${petId}`

	const response = await instance.get(url)
	return response

}

interface IInfoTag {
    id?: number
    text: string
    infoTagTypeId: number
}

export type SavePetNoteResponse = {
    id: number
    dateTime: string
    tags: IPetInfoTagResponse[]
    text: string
    createdAt: Date
    updatedAt: Date
}

interface ISavePetNoteErrorResponse extends IHttpError {
	error: string
}

export const saveNewNote = async (petId: number, payload: any) => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/${petId}/notes/new`

	try {
		const response: AxiosResponse<SavePetNoteResponse> = await instance.post(url, payload)
		return response
	} catch (error) {
		const err = error as AxiosError<ISavePetNoteErrorResponse>
		dashboardApiLogger.logError(err)
		if (err.response) {
			err.response.data.error = 'An error occurred saving note.'
		}

		return err.response ?? {} as AxiosResponse<ISavePetNoteErrorResponse>
	}
}

// TODO: REFACTOR
export const getPetNotes = async (petId: number) => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/${petId}/notes`
	const response = await instance.get(url)
	return response?.data ?? defaultApiResponse
}

interface INoteGraphDataResponse {
	noteGraphData: INoteGraphData[]
}

interface ITrainingSessionGraphDataResponse {
	graphData: ITrainingSessionGraphData[]
}

export const getPetNoteGraphData = async (petId: number, dateStr: string): Promise<AxiosResponse<INoteGraphDataResponse>> => {
	const url = `${process.env.REACT_APP_API_URL}/user/metrics/pet_notes_data/${petId}/${dateStr}`
	try {
		const response: AxiosResponse<INoteGraphDataResponse> = await instance.get(url)
		return response
	} catch (error) {
		const err = error as AxiosError
		dashboardApiLogger.logError(err)
		if(err.response ){
			err.response.data.error = 'An error occurred fetching graph data.'
		}

        return err.response ?? {} as AxiosResponse<INoteGraphDataResponse>
	}
}

export const getPetTrainingSessionNotes = async (petId: number) => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/${petId}/training_sessions`
	try {
		const response = await instance.get(url)

		return response.data
	} catch (e) {
		dashboardApiLogger.logError(e as AxiosError)
	}
}

export const getPetTrainingSessionGraphData = async (petId: number, dateStr: string) => {
	// /metrics/pet_training_sessions_data/:petId/:startDate
	const url = `${process.env.REACT_APP_API_URL}/user/metrics/pet_training_sessions_data/${petId}/${dateStr}`
	try {
		const response: AxiosResponse<ITrainingSessionGraphDataResponse> = await instance.get(url)
		return response
	} catch (error) {
		const err = error as AxiosError
		dashboardApiLogger.logError(err)
		if(err.response ){
			err.response.data.error = 'An error occurred fetching graph data.'
		}

        return err.response ?? {} as AxiosResponse<ITrainingSessionGraphDataResponse>
	}

}

export const getPetTrainingSessionById = async (petId: number, trainingSessionId: number) => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/${petId}/notes/training_sessions/${trainingSessionId}`
	try {
		const response = await instance.get(url)

		return response.data ?? defaultApiResponse
	} catch (e) {
		dashboardApiLogger.logError(e as AxiosError)
	}
}

type GetPetNoteResponse = {
	id: number
	dateTime: string 
	text: string
	tags: IInfoTag[]
}

interface IGetPetNoteErrorResponse extends IHttpError {
	error: string
}

export const getPetNote = async(petId: number, noteId: number): Promise<AxiosResponse<GetPetNoteResponse|IGetPetNoteErrorResponse>> => {
	try {
		const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/${petId}/notes/${noteId}`	
		const response: AxiosResponse<GetPetNoteResponse> = await instance.get(url)
		return response
	} catch (error) {
		const err = error as AxiosError<IGetPetNoteErrorResponse>
		dashboardApiLogger.logError(err)

		if(err.response) {
			err.response.data.error = 'An error occurred getting note'
		}

		return err.response ?? {} as AxiosResponse<IGetPetNoteErrorResponse>
	}
}

interface IUpdatePetNotePayload {
	text: string
	date: MaterialUiPickersDate
	tags: string
}

type UpdatePetNoteResponse = {
	id: number
	dateTime: string
	text: string
	tags: string
}

interface IUpdatePetNoteErrorResponse extends IHttpError {
	error: string
}

export const updatePetNote = async(petId: number, noteId: number, body: IUpdatePetNotePayload): Promise<AxiosResponse<UpdatePetNoteResponse|IUpdatePetNoteErrorResponse>> => {
	try {
		const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/${petId}/notes/${noteId}`

		const response = await instance.put(url, body)
		return response
	} catch (error) {
		const err = error as AxiosError<IUpdatePetNoteErrorResponse>
		dashboardApiLogger.logError(err)

		if(err.response) {
			err.response.data.error = 'An error occurred updating note'
		}

		return err.response ?? {} as AxiosResponse<IUpdatePetNoteErrorResponse>
	}
}

type DeletePetNoteResponse = {
	deleted: boolean
}

interface IDeletePetNoteErrorResponse extends IHttpError {
	error: string
}

export const deletePetNote = async (petId: string, noteId: string): Promise<AxiosResponse<DeletePetNoteResponse|IDeletePetNoteErrorResponse>> => {
	try {
		const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/${petId}/notes/${noteId}`

		const response: AxiosResponse<DeletePetNoteResponse> = await instance.delete(url)
		return response
	} catch (error) {
		const err = error as AxiosError<IDeletePetNoteErrorResponse>
		dashboardApiLogger.logError(err)

		if(err.response) {
			err.response.data.error = 'An error occurred deleting note'
		}
		return err.response ?? {} as AxiosResponse<IDeletePetNoteErrorResponse>
	}
}

export const getPetCuedSkillsById = async(petId: number) => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/cuedSkills/${petId}`

	const response = await instance.get(url)
	return response.data
}

interface IUpdatePetCuedSkillsErrorResponse extends IHttpError {
	error: string
}

export const updatePetCuedSkills = async(petId: number, body: any): Promise<AxiosResponse<any|IUpdatePetCuedSkillsErrorResponse>> => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/cuedskills/${petId}`
	
	try {
		const response: AxiosResponse<any> = await instance.put(url, body)
		return response
	} catch (error) {
		const err = error as AxiosError<IUpdatePetCuedSkillsErrorResponse>
		dashboardApiLogger.logError(err)
		if(err.response) {
			err.response.data.error = 'An error occurred updating cued skills'
		}
		return err.response ?? {} as AxiosResponse<IUpdatePetCuedSkillsErrorResponse>
	}
}

type DeletePetCueResponse = {
	deleted: boolean
}

interface IDeletePetCueErrorResponse extends IHttpError {
	error: string
}

export const deleteCueByIdRequest = async (cueId: number, petId: number): Promise<AxiosResponse<DeletePetCueResponse|IDeletePetCueErrorResponse>> => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/${petId}/cuedSkills/${cueId}`

	try {
		const response: AxiosResponse<DeletePetCueResponse> = await instance.delete(url)
		return response
	} catch (error) {
		const err = error as AxiosError<IDeletePetCueErrorResponse>
		dashboardApiLogger.logError(err)
		if (err.response) {
			err.response.data.error = 'An error occurred deleting cued skill.'
		}

		return err.response ?? {} as AxiosResponse<IDeletePetCueErrorResponse>
	}
}

type DeleteTrainingSessionVideoResponse = {
	deleted: boolean
}

interface IDeleteTrainingSessionVideoErrorResponse extends IHttpError {
	error: string
}

export const deleteVideoRequest = async (trainingSessionId: number, videoId: number, petId: string): Promise<AxiosResponse<DeleteTrainingSessionVideoResponse|IDeleteTrainingSessionVideoErrorResponse>> => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/${petId}/notes/training_sessions/${trainingSessionId}/videos/${videoId}`

	try {
		const response: AxiosResponse<DeleteTrainingSessionVideoResponse> = await instance.delete(url)
		return response
	} catch (error) {
		const err = error as AxiosError<IDeleteTrainingSessionVideoErrorResponse>
		dashboardApiLogger.logError(err)
		if (err.response) {
			err.response.data.error = 'An error occurred removing video.'
		}

		return err.response ?? {} as AxiosResponse<IDeleteTrainingSessionVideoErrorResponse>
	}
}

type DeleteTrainingSessionVideoNoteRowResponse = {
	deleted: boolean
}

interface IDeleteTrainingSessionVideoNoteRowErrorResponse extends IHttpError {
	error: string
}

export const deleteVideoRowRequest = async (petId: string, trainingSessionId: number, noteRowId: number): Promise<AxiosResponse<DeleteTrainingSessionVideoNoteRowResponse|IDeleteTrainingSessionVideoNoteRowErrorResponse>> => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/${petId}/notes/training_sessions/${trainingSessionId}/videoNoteRows/${noteRowId}`

	try {
		const response: AxiosResponse<DeleteTrainingSessionVideoNoteRowResponse> = await instance.delete(url)
		return response
	} catch (error) {
		const err = error as AxiosError<IDeleteTrainingSessionVideoNoteRowErrorResponse>
		dashboardApiLogger.logError(err)
		if (err.response) {
			err.response.data.error = 'An error occurred removing video note row.'
		}

		return err.response ?? {} as AxiosResponse<IDeleteTrainingSessionVideoNoteRowErrorResponse>
	}
}

export const saveNewTrainingSessionNote = async (petId: number, payload: any) => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/${petId}/notes/training_session/new`

	const response = await instance.post(url, payload)
	return response
}

export const updateTrainingSessionNote = async (petId: number, payload: any) => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/${petId}/notes/training_sessions/${payload.id}`

	const response = await instance.put(url, payload)
	return response
}

// return response or error.
export const deletePetInfoTag = async (petId: number, infoTagId: number) => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/pets/${petId}/${infoTagId}`

	try {
		const response = await instance.delete(url)
		return response
	} catch (error) {
		return (error as AxiosError).message
	}
}

interface IAxiosResponseBase {
	error: string
}

interface IPetSummaryRow {
    noteId: number
    petName: string
    petId: number
    description: string
    dateTime: Date
	type: 'note' | 'videoNote'
}

interface IPetSummary extends IAxiosResponseBase{
	petNotes: IPetSummaryRow[]
}

export const getCurrentPetNoteSummaries = async (): Promise<AxiosResponse<IPetSummary>> => {
	const url = `${process.env.REACT_APP_API_URL}/user/profile/dashboard_note_summary`

	try {
		const response: AxiosResponse<IPetSummary> = await instance.get(url)
		return response
	} catch (error) {
		const err = error as AxiosError
		dashboardApiLogger.logError(err)
		// return response
		// add error bool onto response
		if(err.response ){
			err.response.data.error = 'An error occurred.'
		}

        return err.response ?? {} as AxiosResponse<IPetSummary>
	}
}

export interface IActivityGraphDataResponse {
	value: number
	dateTime: string
}

interface IGraphSummary extends IAxiosResponseBase {
	graphData: IActivityGraphDataResponse[]
}

export const getPetYearGraphSummary = async (): Promise<AxiosResponse<IGraphSummary>> => {
	const url = `${process.env.REACT_APP_API_URL}/user/metrics/pet_year_activity`

	try {
		const response: AxiosResponse<IGraphSummary> = await instance.get(url)
		return response
	} catch (error) {
		const err = error as AxiosError
		dashboardApiLogger.logError(err)
		// return response
		// add error bool onto response
		if(err.response ){
			err.response.data.error = 'An error occurred.'
		}

        return err.response ?? {} as AxiosResponse<IGraphSummary>
	}

}

interface IGetExcelNoteSuccessResponse extends Buffer {}

interface IGetExcelNoteErrorResponse extends IHttpError {
	error: string
}

export const getExcelNoteResponse = async (id: number): Promise<AxiosResponse<IGetExcelNoteSuccessResponse|IGetExcelNoteErrorResponse>> => {
	const headers = {
		'Content-Type': 'blob'
	}
	const config: AxiosRequestConfig = {
		responseType: 'arraybuffer',
		headers: headers
	}
	
	const url = `/user/profile/pets/${id}/notes_export`

	try {
		const response: AxiosResponse<IGetExcelNoteSuccessResponse> = await instance.get(url, config)
		return response
	} catch (error) {
		let err = error as AxiosError
		dashboardApiLogger.logError(err)

        if(err.response) {
            err = handleApiResponseError(err, 'An error has occurred retrieving settings')
        }

        return err.response ?? {} as AxiosResponse<IGetExcelNoteErrorResponse>
	}
}

export const getExcelVideoNoteResponse = async (id: number): Promise<AxiosResponse<IGetExcelNoteSuccessResponse|IGetExcelNoteErrorResponse>> => {
	const headers = {
		'Content-Type': 'blob'
	}
	const config: AxiosRequestConfig = {
		responseType: 'arraybuffer',
		headers: headers
	}
	
	const url = `/user/profile/pets/${id}/video_notes_export`

	try {
		const response: AxiosResponse<IGetExcelNoteSuccessResponse> = await instance.get(url, config)
		return response
	} catch (error) {
		let err = error as AxiosError
		dashboardApiLogger.logError(err)

        if(err.response) {
            err = handleApiResponseError(err, 'An error has occurred retrieving settings')
        }

        return err.response ?? {} as AxiosResponse<IGetExcelNoteErrorResponse>
	}
}

interface IUpdatePetStatPayload {
	id?: number
	statName: string
	statValue: number
	statUnit: string
	dateRecorded: Date
}

interface IUpdatePetStatResponse {
	statName: string
	statValue: number
	statUnit: string
	dateRecorded: Date
}

interface IUpdatePetStatErrorResponse extends IHttpError {
	error: string
}

export const updatePetStat = async (id: PetId, payload: IUpdatePetStatPayload): Promise<AxiosResponse<IUpdatePetStatResponse| IUpdatePetStatErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/stats`

	const request = new RequestBuilder<IUpdatePetStatResponse, IUpdatePetStatErrorResponse>()
	.setUrl(urlSuffix)
	.setRequestType('put')
	.setLogger(dashboardApiLogger)
	.setErrorString('An error has occurred saving pet stats')
	.setPayload(payload)

	const response = await request.send()
	return response
}

interface ISaveNewPetStatPayload {
	name: string
	unitType: string
}

interface ISaveNewPetStatResponse {
	id: number
	name: string
	unitType: UnitType
	currentUnit: string
}

interface ISaveNewPetStatErrorResponse extends IHttpError {
	error: string
}

export const saveNewStatForPet = async (id: PetId, payload: ISaveNewPetStatPayload): Promise<AxiosResponse<ISaveNewPetStatResponse|ISaveNewPetStatErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/stats/new`

	const request = new RequestBuilder<ISaveNewPetStatResponse, ISaveNewPetStatErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('post')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error occurred creating new pet stat')
		.setPayload(payload)

	const response = await request.send()
	return response
}

interface IGetPetStatsField {
	id: number
	label: string
	value: number
	unit: string
	type: UnitType
}

interface IGetPetStatsResponse {
	fields: IGetPetStatsField[]
}

interface IGetPetStatsErrorResponse extends IHttpError {
	error: string
}

export const getPetStatsApi = async (id: PetId): Promise<AxiosResponse<IGetPetStatsResponse|IGetPetStatsErrorResponse>> => {	
	const urlSuffix = `/user/profile/pet/${id}/stats`

	const request = new RequestBuilder<IGetPetStatsResponse, IGetPetStatsErrorResponse>()
	.setUrl(urlSuffix)
	.setRequestType('get')
	.setLogger(dashboardApiLogger)
	.setErrorString('An error has occurred getting pet stats')

	const response = await request.send()
	return response
}

interface IDeletePetStatResponse {
	deleted: boolean
}

interface IDeletePetStatErrorResponse extends IHttpError {
	error: string
}

export const deleteStatFromPetApi = async (id: PetId, statId: number): Promise<AxiosResponse<IDeletePetStatResponse|IDeletePetStatErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/stats/${statId}`

	const request = new RequestBuilder<IDeletePetStatResponse, IDeletePetStatErrorResponse>()
	.setUrl(urlSuffix)
	.setRequestType('delete')
	.setLogger(dashboardApiLogger)
	.setErrorString('An error has occurred deleting pet stat')

	const response = await request.send()
	return response
}

interface IGetPetStatHistoryResponseItem {
	id: number
	dateRecorded: Date
	fieldValue: number
	fieldUnit: string
}

interface IGetPetStatHistoryErrorResponse {
	error: string
}

export const getPetStatHistoryApi = async (id: PetId, statId: number): Promise<AxiosResponse<IGetPetStatHistoryResponseItem[]|IGetPetStatHistoryErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/stats/${statId}`

	const request = new RequestBuilder<IGetPetStatHistoryResponseItem[], IGetPetStatHistoryErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('get')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error has occurred getting stat data.')

	const response = await request.send()
	return response
}

export interface IUpdatePetHistoryRowPayload {
	dateRecorded: Date
	fieldValue: number
	fieldUnit: string
}

interface ICurrentStatResponse {
	id: number
	fieldValue: number
	fieldUnit: string
}

interface IUpdatePetHistoryResponse {
	recordingId: Number
	dateRecorded: Date
	fieldValue: number
	fieldUnit: string
	currentValue: ICurrentStatResponse
}

interface IUpdatePetHistoryErrorResponse {
	error: string
}

export const updatePetHistoryRow = async (id: PetId, statId: number, recordingId: number, payload: IUpdatePetHistoryRowPayload): Promise<AxiosResponse<IUpdatePetHistoryResponse|IUpdatePetHistoryErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/stats/${statId}/history/${recordingId}`

	const request = new RequestBuilder<IUpdatePetHistoryResponse,IUpdatePetHistoryErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('put')
		.setPayload(payload)
		.setLogger(dashboardApiLogger)
		.setErrorString('An error occurred updating pet stat history.')

	const response = await request.send()
	return response
}

interface ISaveNewPetHistoryPayload {
	dateRecorded: Date
	fieldValue: number
	fieldUnit: string
}

interface ISaveNewPetHistoryResponse {
	recordingId: number
	dateRecorded: Date
	fieldValue: number
	fieldUnit: string
	currentValue: ICurrentStatResponse
}

interface ISaveNewPetHistoryErrorResponse extends IHttpError {
	error: string
}

export const saveNewRecordingApi = async (id: PetId, statId: number, payload: ISaveNewPetHistoryPayload): Promise<AxiosResponse<ISaveNewPetHistoryResponse|ISaveNewPetHistoryErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/stats/${statId}/history`

	const request = new RequestBuilder<ISaveNewPetHistoryResponse,ISaveNewPetHistoryErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('post')
		.setPayload(payload)
		.setLogger(dashboardApiLogger)
		.setErrorString('An error occurred adding new stat recording.')

	const response = await request.send()
	return response
}

interface IDeletePetHistoryResponse {
	deleted: boolean
	currentValue: ICurrentStatResponse
}

interface IDeletePetHistoryErrorResponse {
	error: string
}

export const deletePetHistoryRow = async (id: PetId, statId: number, recordingId: number): Promise<AxiosResponse<IDeletePetHistoryResponse|IDeletePetHistoryErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/stats/${statId}/history/${recordingId}`

	const request = new RequestBuilder<IDeletePetHistoryResponse,IDeletePetHistoryErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('delete')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error occurred deleting pet stat history.')

	const response = await request.send()
	return response
}

interface IDeletePetVideoNoteResponse {
	deleted: boolean
}

interface IDeletePetVideoNoteErrorResponse {
	error: string
}

export const deleteTrainingSessionNote = async (id: PetId, noteId: number): Promise<AxiosResponse<IDeletePetVideoNoteResponse|IDeletePetVideoNoteErrorResponse>> => {
	const urlSuffix = `/user/profile/pets/${id}/notes/training_sessions/${noteId}`

	const request = new RequestBuilder<IDeletePetVideoNoteResponse, IDeletePetVideoNoteErrorResponse>()
	.setUrl(urlSuffix)
	.setRequestType('delete')
	.setLogger(dashboardApiLogger)
	.setErrorString('An error has occurred deleting video note')

	const response = await request.send()
	return response
}

interface IYoutubeVideoIdObject {
	kind: string
	videoId: string
}

interface IVideoThumbnail {
	url: string
	width: number
	height: number
}

interface IYoutubeVideoItemSnippetThumbnails {
	default: IVideoThumbnail
	high: IVideoThumbnail
	maxres: IVideoThumbnail
	medium: IVideoThumbnail
	standard: IVideoThumbnail
}

interface IYoutubeVideoItemSnippet {
	channelId: string
	channelTitle: string
	description: string
	liveBroadcastContent: string
	publishTime: string
	publishedAt: string
	title: string
	thumbnails: IYoutubeVideoItemSnippetThumbnails
}

export interface IYoutubeVideoItem {
	etag: string
	kind: string
	id: IYoutubeVideoIdObject
	snippet: IYoutubeVideoItemSnippet
}

interface IGetUserVideosResponse {
	prevPageToken: string | undefined
	nextPageToken: string | undefined
	items: IYoutubeVideoItem[]
}

interface IGetUserVideosErrorResponse {
	error: string
}

// TODO: Replace with more defined type
export const getUserVideos = async (query: string, page: string): Promise<AxiosResponse<IGetUserVideosResponse|IGetUserVideosErrorResponse>> => {
	const urlSuffix = `/integrations/user_youtube_videos?query=${query}&pageToken=${page}`

	const request = new RequestBuilder<IGetUserVideosResponse, IGetUserVideosErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('get')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error has occurred retrieving videos')

	const response = await request.send()
	return response
}

interface IDeleteCredentialsResponse {
	deleted: boolean
}

interface IDeleteCredentialsErrorResponse {
	error: string
}

export const deleteGoogleCredentials = async (service_name: string): Promise<AxiosResponse<IDeleteCredentialsResponse|IDeleteCredentialsErrorResponse>> => {
	const urlSuffix = `/integrations/${service_name}`

	const request = new RequestBuilder<IDeleteCredentialsResponse, IDeleteCredentialsErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('delete')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error has occurred revoking the credentials')

	const response = await request.send()
	return response
}

interface IMetricStatsResponseItem {
	value: any
	text: string
	unitType: UnitType
	currentUnit: string
}

interface IGetPetMetricStatsResponse extends Array<IMetricStatsResponseItem>{}

interface IGetPetMetricStatsErrorResponse extends IHttpError {
	error: string
}

export const getStatsForPet = async (id: PetId): Promise<AxiosResponse<IGetPetMetricStatsResponse|IGetPetMetricStatsErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/metrics/statsOptions`

	const request = new RequestBuilder<IGetPetMetricStatsResponse, IGetPetMetricStatsErrorResponse>()
	.setUrl(urlSuffix)
	.setRequestType('get')
	.setLogger(dashboardApiLogger)
	.setErrorString('An error has occurred getting stat options for pet')

	return await request.send()
}

export interface IGraphDataResponse extends Array<IGraphData>{}

export interface IGraphDataErrorResponse extends IHttpError {
	error: string
}

export const getMetricsFromNotesApiPremium = async (id: PetId, type: number, startDate?: Date, endDate?: Date): Promise<AxiosResponse<IGraphDataResponse | IGraphDataErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/metrics/premium/notes/${type}`

	const queryParams = [
		startDate != null ? 'start_date=' + startDate.toISOString() : null,
		endDate != null ? 'end_date=' + endDate.toISOString() : null
	].filter(f => f != null)
	.join('&')

	const completedUrlSuffixWithParams = urlSuffix + '?' + queryParams

	const request = new RequestBuilder<IGraphDataResponse, IGraphDataErrorResponse>()
		.setUrl(completedUrlSuffixWithParams)
		.setRequestType('get')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error has occurred getting data for notes')
		
	return await request.send()
}

export const getMetricsFromStatsApiPremium = async (id: PetId, type: number, startDate?: Date, endDate?: Date): Promise<AxiosResponse<IGraphDataResponse | IGraphDataErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/metrics/premium/stats/${type}`

	const queryParams = [
		startDate != null ? 'start_date=' + startDate.toISOString() : null,
		endDate != null ? 'end_date=' + endDate.toISOString() : null
	].filter(f => f != null)
	.join('&')

	const completedUrlSuffixWithParams = urlSuffix + '?' + queryParams

	const request = new RequestBuilder<IGraphDataResponse, IGraphDataErrorResponse>()
		.setUrl(completedUrlSuffixWithParams)
		.setRequestType('get')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error has occurred getting data for stat')
		
	return await request.send()	
}

// TODO: add query parameters like a date/time range to this.
// This will apply to all of these routes.
export const getMetricsFromNotesApi = async (id: PetId, type: number): Promise<AxiosResponse<IGraphDataResponse | IGraphDataErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/metrics/notes/${type}`

	const request = new RequestBuilder<IGraphDataResponse, IGraphDataErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('get')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error has occurred getting data for notes')

	return await request.send()
}

export const getMetricsFromStatsApi = async (id: PetId, type: number): Promise<AxiosResponse<IGraphDataResponse | IGraphDataErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/metrics/stats/${type}`

	const request = new RequestBuilder<IGraphDataResponse, IGraphDataErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('get')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error has occurred getting data for stats')

	return await request.send()	
}

interface IGraphPayload {
	id: number
	category: string
	startDate?: string
	endDate?: string
	graphType?: string
	premiumGraph: boolean
	sortOrder: number
	typeId: number
	statDefaults?: IStatDefault

}

interface IAddGraphItemPayload {
	category: string
	typeId: number
}

interface IAddGraphItemResponse extends IGraphPayload {}

interface IAddGraphItemErrorResponse extends IHttpError {
	error: string
}

export const addGraphItem = async (id: PetId, payload: IAddGraphItemPayload): Promise<AxiosResponse<IAddGraphItemResponse|IAddGraphItemErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/metrics/graph`

	const request = new RequestBuilder<IAddGraphItemResponse, IAddGraphItemErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('post')
		.setLogger(dashboardApiLogger)
		.setPayload(payload)
		.setErrorString('An error has occurred creating graph for pet')

	return await request.send()
}

interface IGetPetGraphsResponse extends Array<IGraphPayload> {}

interface IGetPetGraphsErrorResponse extends IHttpError {
	error: string
}

export const getPetGraphs = async (id: PetId): Promise<AxiosResponse<IGetPetGraphsResponse | IGetPetGraphsErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/metrics/graphs`

	const request = new RequestBuilder<IGetPetGraphsResponse, IGetPetGraphsErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('get')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error has occurred getting pet graphs')

	return await request.send()
}

interface IDeletePetGraphSuccessResponse {
	deleted: boolean
}

interface IDeletePetGraphErrorResponse extends IHttpError {
	error: string
}

export const deleteGraphFromPetRequest = async (id: PetId, graphId: number): Promise<AxiosResponse<IDeletePetGraphSuccessResponse | IDeletePetGraphErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/metrics/graphs/${graphId}`

	const request = new RequestBuilder< IDeletePetGraphSuccessResponse , IDeletePetGraphErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('delete')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error has occurred deleting pet graph')

	return await request.send()
}

interface IUpdateGraphDataPayload {
	graphType: string
	startDate?: Date
	endDate?: Date
}

interface IUpdatePetGraphDataSuccessResponse {
	graphId: number
	typeId: number
	category: string
	premium: boolean
	graphType: string
	startDate?: string
	endDate?: string
} 

interface IUpdatePetGraphDataErrorResponse extends IHttpError {
	error: string
}

export const updateGraphSearchData = async (id: PetId, graphId: number, payload: IUpdateGraphDataPayload): Promise<AxiosResponse<IUpdatePetGraphDataSuccessResponse | IUpdatePetGraphDataErrorResponse>> => {
	const urlSuffix = `/user/profile/pet/${id}/metrics/graphs/${graphId}`

	const request = new RequestBuilder<IUpdatePetGraphDataSuccessResponse, IUpdatePetGraphDataErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('put')
		.setLogger(dashboardApiLogger)
		.setPayload(payload)
		.setErrorString('An error has occurred updating pet graph')

	return await request.send()
}

interface ISaveUserDeviceSubscriptionResponse {
	id: number
	deviceMetadata: string
	enabled: boolean
	deviceId: string
}

interface ISaveUserDeviceSubscriptionErrorResponse extends IHttpError {
	error: string
}

interface ISaveUserDeviceSubscriptionRequest {
	deviceMetadata: string
	pushSubscription: PushSubscription
	deviceKey: string | null
}

export const saveUserDeviceSubscription = async (payload: ISaveUserDeviceSubscriptionRequest): Promise<AxiosResponse<ISaveUserDeviceSubscriptionResponse|ISaveUserDeviceSubscriptionErrorResponse>> => {
	const urlSuffix = '/user/push/subscription'

	const request = new RequestBuilder<ISaveUserDeviceSubscriptionResponse,ISaveUserDeviceSubscriptionErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('post')
		.setLogger(dashboardApiLogger)
		.setPayload(payload)
		.setErrorString('An error occurred enabling push notifications')

	return await request.send()
}

export const updateUserDeviceSubscription = async (payload: ISaveUserDeviceSubscriptionRequest): Promise<AxiosResponse<ISaveUserDeviceSubscriptionResponse|ISaveUserDeviceSubscriptionErrorResponse>> => {
	const urlSuffix = '/user/push/subscription'

	const request = new RequestBuilder<ISaveUserDeviceSubscriptionResponse,ISaveUserDeviceSubscriptionErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('put')
		.setLogger(dashboardApiLogger)
		.setPayload(payload)
		.setErrorString('An error occurred enabling push notifications')

	return await request.send()
}

interface IUserPushDevice {
	id: number
	name: string
	enabled: boolean
	deviceId: string
}

interface IUserPushDeviceResponse extends Array<IUserPushDevice>{}

interface IUserPushDeviceErrorResponse extends IHttpError {
	error: string
}

export const getPushDevices = async (): Promise<AxiosResponse<IUserPushDeviceResponse| IUserPushDeviceErrorResponse>> => {
	const urlSuffix = '/user/push/subscriptions'

	const request = new RequestBuilder<IUserPushDeviceResponse, IUserPushDeviceErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('get')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error occurred getting push notifications')

	return await request.send()
}

interface IUserPushDeviceTestResponse {
	success: boolean
}

interface IUserPushDeviceTestErrorResponse extends IHttpError {
	error: string
}

export const testPushNotificationRequest = async (id: number): Promise<AxiosResponse<IUserPushDeviceTestResponse | IUserPushDeviceTestErrorResponse>> => {
	const urlSuffix = '/user/push/subscription/test'
	
	const payload = {
		id
	}
	
	const request = new RequestBuilder<IUserPushDeviceTestResponse, IUserPushDeviceTestErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('post')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error occurred testing push notification')
		.setPayload(payload)

	return await request.send()
}

interface IUpdateDeviceNotificationEnabledPayload {
	enabled: boolean
}

interface IUpdateDeviceNotificationEnabledResponse {
	deviceId: number
	enabled: boolean
}

interface IUpdateDeviceNotificationEnabledErrorResponse extends IHttpError {
	error: string
}

export const updateDeviceNotificationEnabled = async (id: number, payload: IUpdateDeviceNotificationEnabledPayload): Promise<AxiosResponse<IUpdateDeviceNotificationEnabledResponse | IUpdateDeviceNotificationEnabledErrorResponse>> => {
	const urlSuffix = `/user/push/subscription/${id}/enabled`

	const request = new RequestBuilder<IUpdateDeviceNotificationEnabledResponse, IUpdateDeviceNotificationEnabledErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('put')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error occurred toggling push notification')
		.setPayload(payload)

	return await request.send()
}

interface IDeleteDeviceSubscriptionResponse {
	deleted: boolean
}

interface IDeleteDeviceSubscriptionErrorResponse {
	error: string
}

export const deleteDeviceNotificationsRequest = async (id: number): Promise<AxiosResponse<IDeleteDeviceSubscriptionResponse|IDeleteDeviceSubscriptionErrorResponse>> => {
	const urlSuffix = `/user/push/subscription/${id}`

	const request = new RequestBuilder<IDeleteDeviceSubscriptionResponse,IDeleteDeviceSubscriptionErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('delete')
		.setLogger(dashboardApiLogger)
		.setErrorString('An error occurred deleting push subscription')

	return await request.send()
}