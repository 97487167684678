import { Link } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'

export const UserLoggedOutLinks = () => {
    return (
        <Grid 
            container 
            spacing={2}>
            <Grid 
                item 
                xs={6}>
                <Typography 
                    component={Link} 
                    to="/signup" 
                    color="inherit">Sign Up</Typography>
            </Grid>
            <Grid 
                item 
                xs={6}>
                <Typography 
                    component={Link} 
                    to="/login" 
                    color="inherit">Log In</Typography>
            </Grid>
        </Grid>

    )
}