import CircularProgress from '@material-ui/core/CircularProgress'

interface ILoaderProps {
    className?: string
    size?: string
}

export const Loader = (props: ILoaderProps) => {
    return <CircularProgress 
        className={props.className} 
        size={props.size}/>
}