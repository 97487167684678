import { Box, Collapse, Divider, Drawer, List, ListItem, ListItemIcon, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import VideoLibraryRoundedIcon from '@material-ui/icons/VideoLibraryRounded'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded'
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useContext, useEffect, useState } from 'react'
import { useAddToHomescreenPrompt } from '../../../hooks/useInstallPrompt'
import { Link, matchPath, useHistory } from 'react-router-dom'
import { sendLogoutRequest } from '../../../app/api/signupApi'
import { ToastContext } from '../../../contexts/ToastContext'
import { UserAuthenticationContext } from '../../../contexts/UserAuthenticationContext'
import { ModuleLogger } from '../../../services/moduleLogger'
import { PetNavigation } from './PetNavigation'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { debounce } from 'lodash'
import { CalendarCurrentDateIcon } from '../../../assets/icons/CalendarTodayIcon'

const useStyles = makeStyles({
    list: {
        width: 240,
        height: '100%'
    },
    drawerLink: {
        minHeight: '48px',
        paddingTop: 0,
        paddingBottom: 0
    },
    fullList: {
        width: 'auto',
    },
    icon: {
      color: '#1B422A',
    },
    menuButton: {
        color: '#1B422A',
        textTransform: 'capitalize',
        fontSize: '1em'
    },
    drawer: {
        width: 240,
        flexShrink: 0,
    },
    dropdownIcon: {
      marginLeft: '1em'
    },
    dashboardDrawerLinkScrolled: {
      padding: '1.25em 1em',
      backgroundColor: '#FFF',
      color: '#1B422A',
      boxShadow: 'none',
      animation: '$clearHighlight .3s'
    },
    dashboardDrawerLink: {
      padding: '1.25em 1em',
      backgroundColor: '#e3f4ef',
      color: '#1B422A',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0),0px 4px 5px 0px rgba(0,0,0,0.14),0px 0px 0px 0px rgba(0,0,0,0.12)',
      animation: '$addHighlight .3s'
    },
    '@keyframes clearHighlight': {
      '0%': {
        backgroundColor: '#e3f4ef',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0),0px 4px 5px 0px rgba(0,0,0,0.14),0px 0px 0px 0px rgba(0,0,0,0.12)',
      },
      '100%': {
        backgroundColor: '#FFF',
        boxShadow: 'none'
      }
    },
    '@keyframes addHighlight': {
      '0%': {
        backgroundColor: '#FFF',
        boxShadow: 'none'
      },
      '100%': {
        backgroundColor: '#e3f4ef',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0),0px 4px 5px 0px rgba(0,0,0,0.14),0px 0px 0px 0px rgba(0,0,0,0.12)',
      },
    },
    expandedDashboardDrawerLink: {
      padding: '1.25em 1em',
      backgroundColor: '#e3f4ef',
      color: '#1B422A',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0),0px 4px 5px 0px rgba(0,0,0,0.14),0px 0px 0px 0px rgba(0,0,0,0.12)',
      paddingBottom: '90px',
      animation: '$grow .3s'
    },
    expandedDashboardDrawerLinkScrolled: {
      padding: '1.25em 1em',
      backgroundColor: '#e3f4ef',
      color: '#1B422A',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0),0px 4px 5px 0px rgba(0,0,0,0.14),0px 0px 0px 0px rgba(0,0,0,0.12)',
      paddingBottom: '26px',
      animation: '$shrink .3s' 
    },
    '@keyframes grow': {
      '0%': {
        paddingBottom: '26px',
      },
      '100%': {
        paddingBottom: '90px'
      }
    },
    '@keyframes shrink': {
      '0%': {
        paddingBottom: '90px',
      },
      '100%': {
        paddingBottom: '26px'
      }
    },
    centerDisplay: {
      display: 'block',
      position: 'relative',
      top: '50%',
      left: '50%',
    },
})

interface IDrawerMenuProps {
    open: boolean
    toggleDrawer: (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
      ) => void
}

export const DrawerMenu = (props: IDrawerMenuProps) => {
    const { open, toggleDrawer } = props

    const history = useHistory()
    const userContext = useContext(UserAuthenticationContext)
    const toaster = useContext(ToastContext)
    const logger = new ModuleLogger()

    const classes = useStyles()
    const theme = useTheme()
    const isLg = useMediaQuery(theme.breakpoints.up('lg'), {noSsr: true})

    const [prompt, promptToInstall] = useAddToHomescreenPrompt()
    const [promptVisible, setPromptVisible] = useState(false)
    const [petNavigationOpen, setPetNavigationOpen] = useState(true)
    const [dashboardHighlight, setDashboardHighlight] = useState(true)

    const userPets = userContext.userProfile.pets

    const match = matchPath(history.location.pathname, {
      path: '/dashboard/pet/:petId',
      exact: false,
      strict: false
    })

    // @ts-ignore
    const foundPetId = match?.params.petId

    useEffect(() => {
        const handlePrompt = () => {
            if(prompt != null) {
                setPromptVisible(true)
            }
        }
  
        handlePrompt()
    })

    const handleWindowScroll = debounce(() => {
      if(window.scrollY >= (foundPetId != null ? 70 : 64)) {
        setDashboardHighlight(false)
      } else if (window.scrollY < (foundPetId != null ? 70 : 64)) {
        setDashboardHighlight(true)
      }
    }, 100)

    useEffect(() => {
      const checkWindowScroll = () => {
        window.addEventListener('scroll', () => handleWindowScroll())
      }

      checkWindowScroll()
    }, [])

    const logout = async (e: React.MouseEvent): Promise<void> => {
        e.preventDefault()
        userContext.setLoading(true)
        try {
            const logoutResponse = await sendLogoutRequest()
            // If status code between 200 and 300, redirect to home.

            if(logoutResponse.status >= 200 && logoutResponse.status <= 300) {
                userContext.setAuthenticated(false)
                toaster.triggerToast('Logged out', 'info')
            } else {
                // throw error.
            }
        } catch (error) {
            logger.logError(error as Error)
        }
        history.push('/')
        userContext.setLoading(false)

        toggleDrawer(false)
    }

    const handlePetExpansion = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setPetNavigationOpen((previous) => !previous)
    }

    const dashboardClassGenerator = () => {
      if(isLg) {
        if(dashboardHighlight) {
          if(foundPetId != null) {
            return classes.expandedDashboardDrawerLink
          } else {
            return classes.dashboardDrawerLink
          }
        } else {
          if(foundPetId != null) {
            return classes.expandedDashboardDrawerLinkScrolled
          } else {
            return classes.dashboardDrawerLinkScrolled
          }
        }
      }

      return ''
    }

    const list = () => (
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height='100%'
          >
            <Box display="flex"
            flexDirection="column">
                <List className={classes.drawerLink}>
                    <ListItem className={dashboardClassGenerator()}>
                        <ListItemIcon className={classes.icon}>
                          <DashboardRoundedIcon color={'inherit'}/>
                        </ListItemIcon>
                        <Typography 
                            component={Link}
                            to="/dashboard" 
                            color="inherit"
                            onClick={toggleDrawer(false)}>Dashboard</Typography>
                        {petNavigationOpen ? <ExpandLess 
                          className={classes.dropdownIcon} 
                          onClick={handlePetExpansion}/> 
                          : <ExpandMore 
                          className={classes.dropdownIcon}
                          onClick={handlePetExpansion}/>}
                    </ListItem>
                    <Collapse 
                      in={petNavigationOpen} 
                      timeout='auto' 
                      unmountOnExit>
                        { userPets.map(pet => {
                            return <PetNavigation {...pet}/>
                        })}
                    </Collapse>
                </List>
                <Divider />
                <List>
                    <ListItem>
                        <ListItemIcon className={classes.icon}>
                          <AccountCircleIcon color={'inherit'}/>
                        </ListItemIcon>
                        <Typography 
                            component={Link} 
                            to="/profile" 
                            color="inherit"
                            onClick={toggleDrawer(false)}>Profile</Typography>
                    </ListItem>
                    </List>
                <Divider />
                {
                  process.env.NODE_ENV !== 'production' ? 
                  <>
                  <List>
                      <ListItem>
                      <CalendarCurrentDateIcon />
                          <Typography
                              component={Link} 
                              to="/planning" 
                              color="inherit"
                              onClick={toggleDrawer(false)}>Planning (Beta)</Typography>
                      </ListItem>
                      </List>
                  <Divider />
                  </> : null
                }
                <List>
                    <ListItem>
                      <ListItemIcon className={classes.icon}>
                        <CheckBoxIcon color={'inherit'}/>
                      </ListItemIcon>
                      <Typography 
                          component={Link} 
                          to="/utilities/checklists" 
                          onClick={toggleDrawer(false)} 
                          color="inherit">Checklists</Typography>
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem>
                      <ListItemIcon className={classes.icon}>
                        <VideoLibraryRoundedIcon color={'inherit'} />
                      </ListItemIcon>
                     <Typography><a 
                            href="https://www.docs.tailscribe.com" 
                            target='_blank' 
                            rel='noopener noreferrer'
                            color="inherit"
                            >Guides</a>
                            </Typography>
                    </ListItem>
                </List>
                {/* Only show if they are a premium user */}
                <Divider />
                <List>
                  <ListItem>
                    <ListItemIcon className={classes.icon}>
                      <StarsRoundedIcon color={'inherit'}/>
                    </ListItemIcon>
                    <Typography
                      component={Link}
                      to="/user/subscription"
                      onClick={toggleDrawer(false)}
                      color="inherit"
                    >
                      My Subscription
                    </Typography>
                  </ListItem>
                </List>
                {
                  promptVisible ?
                  <>
                    <Divider />
                    <List>
                      <ListItem>
                        <ListItemIcon className={classes.icon}>
                          <GetAppIcon color={'inherit'}/>
                        </ListItemIcon>
                        <Typography
                          onClick={promptToInstall}
                        >
                          Download TailScribe
                        </Typography>
                      </ListItem>
                    </List> 
                  </>
                  : 
                  <>
                    <Divider />
                    <List>
                      <ListItem>
                        <ListItemIcon className={classes.icon}>
                          <GetAppIcon color={'inherit'}/>
                        </ListItemIcon>
                        <Typography>
                          <a 
                            href="https://docs.tailscribe.com/docs/Download/" 
                            target='_blank' 
                            rel='noopener noreferrer'
                            color="inherit"
                            >Download TailScribe (Instructions)</a>
                        </Typography>
                      </ListItem>
                    </List> 
                  </>
                }
    
            </Box>
            <Box display="flex"
            flexDirection="column">
                <Divider />
                <List>
                    <ListItem>
                      <ListItemIcon>
                        <ExitToAppOutlinedIcon />
                      </ListItemIcon>
                      <Typography 
                          component={Link} 
                          to="/" 
                          onClick={logout} 
                          color="inherit">Log Out</Typography>
    
                    </ListItem>
                </List>
            </Box>
          </Box>
        </div>
      )

    return <Drawer 
                className={classes.drawer}
                variant={isLg && open ? 'permanent' : 'temporary'}
                anchor={'right'} 
                open={open} 
                onClose={toggleDrawer(false)}>
    {list()}
    </Drawer>
}