import { AppBar, Box, Toolbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    container: {
        // marginTop: '3em',
    },
	footer: {
		backgroundColor: '#409b63',
        paddingTop: '1em',
        paddingBottom: '1em',
	},
	footerContent: {
		width: '18em',
        flexWrap: 'wrap',
		margin: '0 auto',
		[theme.breakpoints.up('sm')]: {
			width: '25em'
		},
		[theme.breakpoints.up('md')]: {
			width: '30em'
		},
		'& a': {
			color: '#e3f4ef',
			'&[disabled]': {
				color: '#576a57',
				cursor: 'default',
				'&:hover': {
					textDecoration: 'none'
				}
			}
		},
	},
    copyright: {
        margin: '1em',
        color: '#FFF'
    },
    copyrightText: {
        color: '#FFF'
    }
}))

export const Footer = () => {    
    const classes = useStyles()

    return (
        <Box className={classes.container}>
            <AppBar 
                className={classes.footer}
                position="static">
                <Toolbar
                    className={classes.footerContent}
                    component={Box}
                    display="flex"
                    justifyContent="space-between">
                    <a 
                        href="https://www.docs.tailscribe.com" 
                        target='_blank' 
                        rel='noopener noreferrer'>User Guides</a>
                    <Link to="/premium">Get Premium</Link>
                    <Link to="/contact">Contact Us</Link>
                </Toolbar>
                <Box 
                    className={classes.footerContent}
                    display="flex"
                    justifyContent="space-between"
                    flexWrap="wrap">
                    <Link to="/terms">Terms and Conditions</Link>
                    <Link to="/privacy">Privacy Policy</Link>
                    <Link to="/attributions">Attributions</Link>
                </Box>
                <Box
                    className={classes.copyright}
                >
                    <Typography
                        className={classes.copyrightText}
                    >© Pawsitech LLC 2023</Typography>
                </Box>
            </AppBar>
        </Box>
    )
}
