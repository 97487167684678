
import { createContext } from 'react'
import { toastSeverity } from '../features/components/ToastComponent'

export interface IToastContextProps {
    isToastOpen: boolean
    toastMessage: string
    setToastOpen: (state: boolean) => void
    triggerToast: (toastMessage: string, toastLevel: toastSeverity) => void
}

const defaultContextValues: IToastContextProps = {
    isToastOpen: false,
    toastMessage: '',
    setToastOpen: ( _state: boolean): void => { return },
    triggerToast: ( _toastMessage: string, _toastLevel: toastSeverity ): void => { return }
}

export const ToastContext = createContext(defaultContextValues)