import { captureException } from '@sentry/react'

export class ModuleLogger {
  logError (e: Error): void {
    const nodeEnv = process.env.NODE_ENV ?? ''
    if (nodeEnv === 'production') {
      captureException(e)
    } else if (nodeEnv !== 'test') {
      console.error(e)
    }
  }
}