import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { ModuleLogger } from '../../services/moduleLogger'
import { defaultApiResponse } from '../utils/constants'
import { handleApiResponseError } from '../utils/responseHelpers'
import { instance } from './axiosConfig'

type RequestSuffix = string

type RequestUrl = string

type RequestType = 'get' | 'post' | 'put' | 'delete'

type ErrorString = string

export interface IRequestPayload extends Object {}

const requestMultiplexer = async (url: RequestUrl, type: RequestType, axiosInstance: AxiosInstance, payload?: IRequestPayload, ): Promise<AxiosResponse<any>> => {
    if(type === 'get') {
        return await axiosInstance.get(url)
    } else if (type === 'delete') {
        return await axiosInstance.delete(url)
    } else if (type === 'post') {
        const response = await axiosInstance.post(url, payload)
        return response
    } else if (type === 'put') {
        return await axiosInstance.put(url, payload)
    }

    throw new Error('Invalid request type. Current valid requests are get, post, put, and delete.')
}

export const handleRequest = async <T,U>(urlSuffix: RequestSuffix, requestType: RequestType, logger?: ModuleLogger, errorString: ErrorString = 'An error has occurred', payload?: IRequestPayload): Promise<AxiosResponse<T|U>> => {
    const url = `${process.env.REACT_APP_API_URL}${urlSuffix}`

    try {
        const response: AxiosResponse<T> = await requestMultiplexer(url, requestType, instance, payload)
        return response ?? defaultApiResponse
    } catch (error) {
        let err = error as AxiosError<U>

        if (logger != null){
            logger.logError(err)
        }

        if(err.response) {
            err = handleApiResponseError(err, errorString)
        }

        return err.response ?? {} as AxiosResponse<U>
    }
}

export class RequestBuilder<T,U> {
    urlSuffix: RequestSuffix | null
    headers: {[key: string]: string}
    requestType: RequestType
    logger: ModuleLogger | null
    errorString: string
    payload?: IRequestPayload

    constructor() {
        this.urlSuffix = null
        this.headers = {}
        this.requestType = 'get'
        this.logger = null
        this.errorString = 'An error has occurred'
        this.payload = undefined
    }

    setUrl (url: RequestSuffix): RequestBuilder<T,U> {
        this.urlSuffix = url
        return this
    }

    setHeaders (headerPayload: {[key: string]: string}): RequestBuilder<T,U> {
        this.headers = headerPayload
        return this
    }

    setRequestType (request: RequestType): RequestBuilder<T,U> {
        this.requestType = request
        return this
    }

    setLogger (inputLogger: ModuleLogger): RequestBuilder<T,U> {
        this.logger = inputLogger
        return this
    }

    setErrorString (errorStr: string): RequestBuilder<T,U> {
        this.errorString = errorStr
        return this
    }

    setPayload (inputPayload: IRequestPayload): RequestBuilder<T,U> {
        this.payload = inputPayload
        return this
    }

    async send (): Promise<AxiosResponse<T|U>> {
        try {
            if(this.urlSuffix != null) {
                const response: AxiosResponse<T> = await requestMultiplexer(this.urlSuffix, this.requestType, instance, this.payload)
                return response ?? defaultApiResponse
            } else {
                throw new Error('Please provide a url')
            }
        } catch (error) {
            let err = error as AxiosError<U>
    
            if (this.logger != null){
                this.logger.logError(err)
            }
    
            if(err.response) {
                err = handleApiResponseError(err, this.errorString)
            }
    
            return err.response ?? {} as AxiosResponse<U>
        }
    }
}