import { Box, ListItemIcon, Typography, makeStyles } from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { clsx } from 'clsx'

const useStyles = makeStyles({
    centerDisplay: {
        display: 'block',
      },
      planningTypography: {
        marginLeft: '38px'
      },
      icon: {
        color: '#1B422A',
      },
      dayNumber: {
        display: 'block',
        position: 'absolute',
        left: '20.5px',
        top: '14px',
        fontSize: '.8em'
      }
})

export const CalendarCurrentDateIcon = () => {
    const classes = useStyles()
    return <Box>
    <ListItemIcon className={clsx(classes.icon, classes.centerDisplay)}>
      <CalendarTodayIcon color={'inherit'}/>
    </ListItemIcon>
    <Typography className={classes.dayNumber}>{(new Date()).getDate()}</Typography>
  </Box>
}