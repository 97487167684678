import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorkerRegistration'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { install } from 'resize-observer'

install()

// prettifies the hash from facebook redirects.
if (window.location.hash === '#_=_'){
  // eslint-disable-next-line no-restricted-globals
  history.replaceState 
    // eslint-disable-next-line no-restricted-globals
    ? history.replaceState(null, '', window.location.href.split('#')[0])
    : window.location.hash = ''
}

if(process.env.NODE_ENV !== 'test') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_RELEASE,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <BrowserRouter>
            <App />
      </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.register()
