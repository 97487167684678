import { IconButton, Snackbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

export type toastSeverity = 'error' | 'warning' | 'info' | 'success'

export interface IToastProps {
    isToastOpen: boolean
    toastMessage: string
    toastLevel?: toastSeverity
}

export interface IToastComponentProps extends IToastProps {
    setToastOpen: (state: boolean) => void
}

export const ToastComponent = (props: IToastComponentProps) => {
    const { isToastOpen, toastMessage, setToastOpen, toastLevel='info' } = props

    const handleToastClose = () => {
        setToastOpen(false)
    }

    return (
        <>
        	<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				open={isToastOpen}
				autoHideDuration={3000}
				onClose={handleToastClose}
				action={
					<IconButton 
						size="small" 
						aria-label="close" 
						color="inherit" 
						onClick={handleToastClose}>
						<CloseIcon fontSize="small" />
					</IconButton>
				}
			>
                <Alert 
                    onClose={handleToastClose} 
                    severity={toastLevel}>{toastMessage}</Alert>
            </Snackbar>
        </>
    )
}