export interface IExponentialTimeoutReturnProps {
    clear: () => void
}

export const exponentialTimeout = (delay: number, fn: () => void): IExponentialTimeoutReturnProps => {

  let id: NodeJS.Timeout | null

  const invoker = function() {
    fn()
    delay = Math.min(Math.floor(delay * 2), 86400000)
    if (delay) {
      id = setTimeout(invoker, delay)
    } else {
      id = null
    }
  }

  // start it off
  id = setTimeout(invoker, delay)

  return {
    clear: function() {
      if (id) {
        clearTimeout(id)
        id = null
      }
    }
    }
}