import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, makeStyles, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { PetIconRow } from '../pages/premium/petIconRow'
import StarRoundedIcon from '@material-ui/icons/StarRounded'

export interface IPremiumModalProps {
    open: boolean
}

export interface IPremiumModalComponentProps extends IPremiumModalProps {
    setOpen: (state: boolean) => void
}

const useStyles = makeStyles((theme) => ({
    dialogButtons: {
        display: 'flex',
        flexDirection: 'column'
    },
    signupButton: {
        margin: theme.spacing(1),
        backgroundColor: '#5676f4',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#7f95ee'
        }
    },
    dialogTitle: {
        fontWeight: 'bold',
        fontSize: '1.5em',
        textShadow: '1px 1px 1px #FFF'
    },
    dialogBackground: {
        background: 'linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(\'https://res.cloudinary.com/dsv5abc3a/image/upload/v1676514833/collie-in-water-ge5eaf3e29_640_obonf0.jpg\')',
        backgroundPosition: '60% 30%',
    },
    dialogContainer: {
        margin: '0em 2em'
    },
    premiumText: {
        margin: '1em',
        textShadow: '1px 1px 1px #FFF'
    },
    textShadow: {
        textShadow: '1px 1px 1px #FFF'
    }
}))

export const PremiumModalComponent = (props: IPremiumModalComponentProps) => {
    const classes = useStyles()
    const { open, setOpen } = props

    const handleDialogClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Dialog 
                open={open} 
                onClose={handleDialogClose}>
                    <Box className={classes.dialogBackground}>
                        <DialogTitle>
                            <Typography 
                                align="center" 
                                variant={'h5'}
                                className={classes.dialogTitle}
                                >
                                Sign Up for Premium!
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Box display="flex"
                                alignItems="stretch"
                                justifyContent='center'
                                flexDirection="column"
                                className={classes.dialogContainer}
                            >
                                <Typography 
                                    variant={'body1'}
                                    className={classes.premiumText}
                                    align='center'
                                    >Sign up for Premium and get additional features</Typography>

                                <Divider variant='middle'/>

                                <Box paddingX={0}
                                marginX={1}>
                                    <PetIconRow 
                                        shadow={true}
                                        text={'Unlimited Checklists'}
                                    />
                                    <PetIconRow 
                                        shadow={true}
                                        text={'Cue Analysis'}
                                    />
                                    <PetIconRow 
                                        shadow={true}
                                        text={'Excel Export'}
                                    />
                                    <PetIconRow 
                                        shadow={true}
                                        text={'Graph Filters'}
                                    />
                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions className={classes.dialogButtons}>
                            <Button 
                                color={'primary'}
                                className={classes.signupButton}
                                component={Link}
                                variant={'outlined'}
                                to="/premium"
                                startIcon={<StarRoundedIcon/>}
                                endIcon={<StarRoundedIcon/>}
                                onClick={handleDialogClose}>Sign me up!</Button>
                        </DialogActions>

                    </Box>
            </Dialog>
        </>
    )
}