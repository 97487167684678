import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { DrawerMenu } from './navigation/DrawerMenu'
// import { useMediaQuery } from '@material-ui/core'
// import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
    list: {
        width: 240,
        height: '100%'
    },
    fullList: {
        width: 'auto',
    },
    menuButton: {
        color: '#1B422A',
        textTransform: 'capitalize',
        fontSize: '1em'
    }
})

interface ITemporaryDrawerProps {
  initializedOpen: boolean
}

export const TemporaryDrawer = (props: ITemporaryDrawerProps) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(props.initializedOpen)

  useEffect(() => {
    setOpen(props.initializedOpen)
  }, [props.initializedOpen])

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setOpen(open)
  }

  return (
    <div>
      {
        <React.Fragment key={'menu'}>
          <Button 
            className={classes.menuButton}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={toggleDrawer(true)}>Menu</Button>
          <DrawerMenu open={open}
            toggleDrawer={toggleDrawer}
          />
        </React.Fragment>
      }
    </div>
  )
}