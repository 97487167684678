import { createTheme } from '@material-ui/core/styles'

export const mainTheme = createTheme({
    palette: {
        primary: {
            main: '#409b63'
        },
        secondary: {
            main: '#7e60ec'
        },
        text: {
            primary: '#1B422A'
        }
    },
    typography: {
        allVariants: {
            color: '#1B422A'
        },
        h1: {
            fontSize: '2em'
        },
        h2: {
            fontSize: '1.5em',
            margin: '1em 0'
        },
    }
})