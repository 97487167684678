import axios, { AxiosError, AxiosResponse } from 'axios'
import { ILoginPayload } from '../../features/pages/login/LoginForm'
import { instance } from './axiosConfig'
import { RequestBuilder, handleRequest } from './base'
import { IHttpError } from './types'
import { IUserIntegrations, IUserPet } from '../types/types'
import { ModuleLogger } from '../../services/moduleLogger'

const signupLogger = new ModuleLogger()

export interface ISignupPayload {
	email: string
	password: string
}

export const sendPasswordResetRequest = async (body: {email: string}): Promise<AxiosResponse> => {
	const url = `${process.env.REACT_APP_API_URL}/password_reset_init`

	let response

	try {
		response = await axios.post(url, body)
		return response
	} catch(err) {
		const result: AxiosError = err as AxiosError
		return result.response ?? {} as AxiosResponse
	}
}

export const sendPasswordReset = async (body: { email: string; password: string; token: string}): Promise<AxiosResponse> => {
	const url = `${process.env.REACT_APP_API_URL}/password_reset_set`

	let response

	try {
		response = await axios.post(url, body)
		return response
	} catch(err) {
		const result: AxiosError = err as AxiosError
		return result.response ?? {} as AxiosResponse
	}
}

export const loginWithFacebook = async () => {
	const url = `${process.env.REACT_APP_API_URL}/facebook`

	let response

	try {
		response = await axios.get(url)
	} catch(err) {
		response = err
	}

	return response
}

export const signUp = async (payload: ISignupPayload) => {
	const url = `${process.env.REACT_APP_API_URL}/signup`

	let response

	try {
		response = await instance.post(url, payload)
		return response
	} catch(err) {
		response = err
		// @ts-ignore
		return response.response
	}
}

interface ILoginResponse {
	token: string
}

interface ILoginErrorResponse extends IHttpError {
	error: string
}

export const login = async (payload: ILoginPayload): Promise<AxiosResponse<ILoginResponse|ILoginErrorResponse>> => {
	const urlSuffix = '/login'

	const request = new RequestBuilder<ILoginResponse, ILoginErrorResponse>()
		.setUrl(urlSuffix)
		.setRequestType('post')
		.setLogger(signupLogger)
		.setErrorString('An error has occurred logging in')
		.setPayload(payload)

	const response = await request.send()
	return response
}

export const sendLogoutRequest = async () => {
	const url = `${process.env.REACT_APP_API_URL}/logout`

	const response = await instance.post(url, {})

	return response
}

type CheckAuthenticationResponse = {
	authenticated: boolean
	isPremium: boolean
	premiumLevel: number
	checklistsCount: number
	pets: IUserPet[]
	integrations: IUserIntegrations
}

interface ICheckUserAuthenticationErrorResponse extends IHttpError {
	error: string
}

export const checkUserAuthentication = async (): Promise<AxiosResponse<CheckAuthenticationResponse|ICheckUserAuthenticationErrorResponse>> => {
	try {
		const url = `${process.env.REACT_APP_API_URL}/user/checkAuthentication`

		const response: AxiosResponse<CheckAuthenticationResponse> = await instance.get(url)
		return response
	} catch (error) {
		const err = error as AxiosError<ICheckUserAuthenticationErrorResponse>
		// No need to log. It is a 401.
		const response = {
			data: {
				error: 'Unauthorized',
				...err.response	
			}
		} as AxiosResponse<ICheckUserAuthenticationErrorResponse>

		return response
	}
}

interface IRefreshAuthenticationResponse {
	authenticated: boolean
}

interface IRefreshAuthenticationErrorResponse extends IHttpError {
	error: string
}

export const refreshUserAuthentication = async (): Promise<AxiosResponse<IRefreshAuthenticationResponse|IRefreshAuthenticationErrorResponse>> => {
	const urlSuffix = '/refresh'
	return handleRequest(urlSuffix, 'get')
}
