import { makeStyles } from '@material-ui/core/styles'

import { Box, ThemeProvider } from '@material-ui/core'

import { useProfile } from './hooks/useProfile'
import { Navbar } from './features/pages/global/Navbar'
import { IUseProfileHook, UserAuthenticationContext } from './contexts/UserAuthenticationContext'
import { CookieConsent } from './features/pages/global/CookieConsent'
import { Footer } from './features/pages/global/Footer'
import { ToastComponent } from './features/components/ToastComponent'
import { IToastContextProps, ToastContext } from './contexts/ToastContext'
import { useToast } from './hooks/useToast'
import { IPremiumModalContextProps, PremiumModalContext } from './contexts/PremiumModalContext'
import { usePremiumModal } from './hooks/usePremiumModal'
import { PremiumModalComponent } from './features/components/PremiumModalComponent'
import { mainTheme } from './themes/mainTheme'
import tiledBackground from './assets/svg/tiled_background.svg'
import { AppRouter } from './AppRouter'
import { useCookie } from './hooks/useCookie'

const useStyles = makeStyles((theme) => ({
	app: {
		textAlign: 'center',
		backgroundColor: '#fff',
		backgroundImage: `url(${tiledBackground})`
	},
	rightNav: {
		maxWidth: '10em',
		[theme.breakpoints.up('sm')]: {
			maxWidth: '15em'
		},
	},
	navBar: {
		backgroundColor: '#409b63',
		color: '#e3f4ef',
		'& a': {
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'underline'
			}
		}
	},
	navBarTypography: {
		textDecoration: 'none',
		color: 'inherit',
	},
	footer: {
		backgroundColor: '#409b63',
	},
	footerContent: {
		width: '20em',
		margin: '0 auto',
		[theme.breakpoints.up('sm')]: {
			width: '25em'
		},
		[theme.breakpoints.up('md')]: {
			width: '30em'
		},
		'& a': {
			color: '#e3f4ef',
			'&[disabled]': {
				color: '#576a57',
				cursor: 'default',
				'&:hover': {
					textDecoration: 'none'
				}
			}
		},
	},
}))

function App() {
	const { initializeDeviceCookie } = useCookie()
	initializeDeviceCookie()
	
	const classes = useStyles()

	const { toastProps, triggerToast, setToastOpen } = useToast()
	const toastContext: IToastContextProps = {
		setToastOpen,
		triggerToast,
		...toastProps
	}

	const { open, setModalOpen } = usePremiumModal()
	const premiumModalContext: IPremiumModalContextProps = {
		open: open,
		setOpen: setModalOpen,
	}

	// toaster is required to notify that profile hook has run successfully.
	const userProfile: IUseProfileHook = useProfile({triggerToast})
	const isUserAuthenticated = userProfile?.userProfile?.isAuthenticated ?? false
	const isUserAuthenticationChecked = userProfile?.userProfile?.authenticationChecked ?? false

  return (
	<ThemeProvider theme={mainTheme}>

	<UserAuthenticationContext.Provider 
		value={userProfile}>
		<ToastContext.Provider 
			value={toastContext}>
				<PremiumModalContext.Provider
					value={premiumModalContext}>
					<Box 
						className={classes.app}
						display="flex"
						flexDirection="column"
						minHeight="100vh">
							<Navbar />
								<AppRouter 
									userProfile={userProfile} 
									isUserAuthenticated={isUserAuthenticated} 
									isUserAuthenticationChecked={isUserAuthenticationChecked}/>
						<Footer />
						<PremiumModalComponent
						 	open={open}
							setOpen={setModalOpen}
						/>
						<ToastComponent 
							{...toastProps} 
							setToastOpen={setToastOpen}/>
						<CookieConsent />
					</Box>
			</PremiumModalContext.Provider>
		</ToastContext.Provider>	
	</UserAuthenticationContext.Provider>
	</ThemeProvider>
  )
}

export default App
