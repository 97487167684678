import { useCookies } from 'react-cookie'
import { v4 } from 'uuid'

interface IUseCookieHookOptions {
  initializeDeviceCookie: () => void
  getCookie: (key: string) => string | null
}

export const useCookie = (): IUseCookieHookOptions => {
  const [cookies, setCookie] = useCookies()
  
  const initializeDeviceCookie = () => {
    if(!cookies.deviceKey) {
      setCookie('deviceKey', v4())
    }

    console.log(cookies['deviceKey'])
  }

  const getCookie = (key: string): string | null => {
    if(key in cookies) {
      return String(cookies[key])
    } else {
      console.log('Cookie not found!')
    }

    return null

  }

  return {
    initializeDeviceCookie,
    getCookie
  }
}