import { useState } from 'react'
import { IToastProps, toastSeverity } from '../features/components/ToastComponent'

export interface IUseToastHook {
    toastProps: IToastProps
    triggerToast: () => void
    setToastOpen: (state: boolean) => void
}

export const useToast = () => {
	const [toastProps, setToastProps] = useState<IToastProps>({isToastOpen: false, toastMessage: ''})

	const triggerToast = (toastMessage: string, toastLevel?: toastSeverity) => {
		const newToastProps = {
			isToastOpen: true,
			toastMessage: toastMessage,
            toastLevel: toastLevel
		}
		setToastProps(newToastProps)
	}

	const setToastOpen = (state: boolean) => {
		setToastProps({
			isToastOpen: state,
            // Leave message alone so that it doesn't look weird.
			toastMessage: toastProps.toastMessage,
            toastLevel: toastProps.toastLevel
		}
		)
	}

    return {
        toastProps,
        triggerToast,
        setToastOpen,
    } as IUseToastHook
}